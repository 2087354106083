import type { JSX } from "react";
import { Dialog, DialogActions, DialogTitle, Paper, Typography } from "@mui/material";

import CustomButton from "../../Action/Button/ButtonCustom";

interface DialogImportSummaryProps {
    isOpen: boolean,
    isLoading: boolean,

    onClose(isToDelete: boolean): any,
    onRedirection(): any,

    content: JSX.Element
}

export function Success({text}: { text: string }) {
    return (
        <div style={{ display: "flex", alignItems: "center" }}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <mask id="mask0_4479_17481" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
                    <rect width="24" height="24" fill="#D9D9D9"/>
                </mask>
                <g mask="url(#mask0_4479_17481)">
                    <path d="M9.55001 17.9996L3.85001 12.2996L5.27501 10.8746L9.55001 15.1496L18.725 5.97461L20.15 7.39961L9.55001 17.9996Z" fill="#ADD288"/>
                </g>
            </svg>
            <p style={{ marginLeft: "5px" }}>{text}</p>
        </div>
    );
}

export function Error({text}: { text: string }) {
    return (
        <div style={{ display: "flex", alignItems: "center" }}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <mask id="mask0_4479_17487" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
                    <rect width="24" height="24" fill="#D9D9D9"/>
                </mask>
                <g mask="url(#mask0_4479_17487)">
                    <path d="M6.4 19L5 17.6L10.6 12L5 6.4L6.4 5L12 10.6L17.6 5L19 6.4L13.4 12L19 17.6L17.6 19L12 13.4L6.4 19Z" fill="#F2645B"/>
                </g>
            </svg>
            <p style={{ marginLeft: "5px" }}>{text}</p>
        </div>
    );
}

function Loading() {
    return (
        <div style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }}>
            <Paper elevation={0} style={{
                display: "flex",
                flexDirection: 'column',
                justifyContent: "center",
                alignItems: "center",
                padding: 15
            }}>
                <img src={require("../../../../Assets/loading.gif")} alt={"loading"} style={{ width: 80, height: 80 }}/>
                <Typography variant={"caption"} fontSize={15} style={{ color: "#18161E", margin: "1em auto auto" }}>Importation des données</Typography>
            </Paper>
        </div>
    );
}

function Report({onRedirection, content}: { onRedirection(): any, content: JSX.Element }) {
    return <>
        {content}
        <DialogActions>
            <CustomButton type={"button"} text={"Redirection vers LEA"} onClick={onRedirection}/>
            <CustomButton type={"submit"} text={"Fermer"}/>
        </DialogActions>
    </>;
}

export default function DialogImportSummary({isOpen, isLoading, onClose, onRedirection, content}: DialogImportSummaryProps) {
    async function onSubmit(e: any) {
        e.preventDefault();
        onClose(false);
    }

    return (
        <Dialog
            PaperProps={{style: {borderRadius: 8}}}
            // @ts-ignore
            component={"form"}
            onSubmit={onSubmit}
            open={isOpen}
            onClose={() => onClose(false)}
            fullWidth
            maxWidth={"sm"}>
            <DialogTitle className={"bg-gradient-to-r from-BASE_PURPLE to-BASE_BLUE"}/>
            {isLoading ? <Loading/> : <Report onRedirection={onRedirection} content={content}/>}
        </Dialog>
    );
}
