import LoadingButton from '@mui/lab/LoadingButton';
import React from "react";
import {Popover} from "@mui/material";

interface MetaDataPopover {
    onClick?(event: React.MouseEvent<HTMLButtonElement> | undefined): any;

    label: string;
    icon?: JSX.Element;
    isDisable?: boolean;
}

interface CustomButtonProps {
    text?: string;

    onClick?(event: React.MouseEvent<HTMLButtonElement> | undefined): any;

    type?: "button" | "submit";
    disabled?: boolean;
    isLoading?: boolean;
    isForPopOver?: boolean;
    startIcon?: any | null;
    popOverData?: MetaDataPopover[];
    link? : string | undefined;
    hidden?: boolean;
    sx?: any

    title?: string;
}

export default function CustomButton({
                                         startIcon = null, text, type = 'button', onClick = () => {
    }, disabled = false, isLoading = false, isForPopOver = false, popOverData = [], link = undefined, hidden = false, sx = {}, title
                                     }: CustomButtonProps) {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const handleOpenPopOver = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return <>
        <LoadingButton
            title={title}
            href={link} startIcon={startIcon} type={type} loading={isLoading} variant={'contained'}
                       disabled={disabled || isLoading} onClick={isForPopOver ? handleOpenPopOver : onClick} size={"large"} sx={{
            borderRadius: 10,
            fontWeight: '500',
            textTransform: 'none',
            fontFamily: 'Work Sans',
            display: `${!!hidden && 'none'}`,
            color : `${!disabled && 'white'}`,
            ...sx
        }} className={`${!disabled && 'bg-gradient-to-r from-BASE_PURPLE to-BASE_BLUE '} cursor-pointer`} endIcon={isForPopOver ?
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                 stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5"/>
            </svg> : null}>
            {text}
        </LoadingButton>
        <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
        >
            <ul className="items-center bg-purple text-base float-left list-none text-left rounded-lg shadow-lg mt-1 m-0 bg-clip-padding border-none">
                {popOverData.map((elem, index) => <li key={`${elem.label} - ${index} - pop over button`}>
                        <button type={'button'} onClick={elem.onClick} disabled={elem.isDisable}
                                className={`dropdown-item text-sm py-2 px-4 font-normal block w-full bg-transparent ${elem.isDisable ? 'text-gray-200' : 'text-gray-700'} hover:${elem.isDisable ? 'bg-transparent' : 'bg-gray-100'} flex flex-row space-x-2`}
                        >
                            {elem.icon}
                            <p className={"font-semibold"}>{elem.label}</p>
                        </button>
                    </li>
                )}
            </ul>
        </Popover>
    </>;
}