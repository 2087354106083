/**
 * Returns the width of the given text in the given font.
 *
 * @param {string} text The text to measure.
 * @param {string} font The font to use (default: "14px Work Sans, sans-serif").
 *
 * @returns {number} The width of the text in the given font.
 */
export default function getTextWidth(text: string, font: string = "14px Work Sans, sans-serif"): number
{
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");

    if (!context)
        return 0;
    context.font = font;
    return context.measureText(text).width;
}

/**
 * Returns the width of the longest text in the given array of texts in the given font.
 *
 * @param {string[]} texts The texts to measure.
 * @param {string} font The font to use (default: "14px Work Sans, sans-serif").
 *
 * @returns {number} The width of the longest text in the given array of texts in the given font.
 */
export function getLongestTextWidth(texts: string[], font: string = "14px Work Sans, sans-serif"): number
{
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    let width: number = 0;

    if (!context)
        return width;
    context.font = font;
    texts.forEach((text: string): void => {
        const textWidth = context.measureText(text).width;

        if (textWidth > width)
            width = textWidth;
    });
    return width;
}
