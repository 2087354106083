import React, { createContext, useContext, useState, useEffect, ReactNode } from "react";

interface AppHeightContextValue {
    bodyOffsetTop: number;
}

const AppHeightContext = createContext<AppHeightContextValue | undefined>(undefined);

export const useAppHeight = (): AppHeightContextValue => {
    const context = useContext(AppHeightContext);

    if (!context) {
        throw new Error("useAppHeight must be used within an AppHeightProvider");
    }

    return context;
};

export const AppHeightProvider = ({ children }: { children: ReactNode }): JSX.Element => {
    const [bodyOffsetTop, setBodyOffsetTop] = useState<number>(0);

    useEffect(() => {
        const updateOffsetTop = () => {
            setBodyOffsetTop(document.body.getBoundingClientRect().top);
        };

        updateOffsetTop();

        window.addEventListener("scroll", updateOffsetTop);

        return () => {
            window.removeEventListener("scroll", updateOffsetTop);
        };
    }, []);

    return (
        <AppHeightContext.Provider value={{ bodyOffsetTop }}>
            {children}
        </AppHeightContext.Provider>
    );
};
