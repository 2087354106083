import React, {useCallback, useState} from "react";
import { useHandleError } from "../Tools/HandleError/HandleError";
import CustomButton from "../Tools/Action/Button/ButtonCustom";
import CustomInput from "../Tools/Action/Inputs/CustomInput";
import CustomSelect from "../Tools/Action/Inputs/CustomSelect";
import * as request from "../Tools/Utils/APIRequests/request";
import {useHandleSuccess} from "../Tools/HandleSuccess/HandleSuccess";
import {useNavigate} from "react-router-dom";

const subjectList = [
    {
        value: "Signaler un dysfonctionnement",
        name: "Signaler un dysfonctionnement",
    },
    {
        value: "Paramétrage",
        name: "Paramétrage",
    },
    {
        value: "Autre",
        name: "Autre",
    },
];

export default function ContactUsFor() {
    const [mySubject, setMySubject] = useState(subjectList[2].value);
    const [lastname, setLastname] = useState("");
    const [firstname, setFirstname] = useState("");
    const [company, setCompany] = useState("");
    const [phone, setPhone] = useState<number | "">("");
    const [mail, setMail] = useState("");
    const [message, setMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const handleSuccess = useHandleSuccess();
    const handleError = useHandleError();
    const navigate = useNavigate();
    const navigateToDisconnect = useCallback(() => navigate("/dashboard/disconnect"), [navigate]);

    async function onSubmit(event: { preventDefault: () => void; }) {
        event.preventDefault();
        const req = await request.post(
            `${process.env.REACT_APP_LEA_API}/contact`,
            {
                nom: lastname,
                prenom: firstname,
                societe: company,
                telephone: phone,
                courriel: mail,
                objet: mySubject,
                message: message,
            });

        if (req.isSuccessful) {
            handleSuccess(req.response.data.message);
            setIsLoading(false);
        } else if (req.isSessionExpired) {
            handleError(req.error, () => {
                setIsLoading(false);
                navigateToDisconnect();
            }, req.error?.response?.data?.message ?? request.EXPIRED_SESSION_MESSAGE, true);
        } else if (req.error.response) {
            handleError(req.error, () => {
                setIsLoading(false);
            }, req.error.response.data.message);
        }
    }

    return (
        <form
            className={
                "flex flex-col h-full col-span-2 bg-white rounded-lg font-worksans shadow-lg"
            }
            onSubmit={onSubmit}
        >
            <div className="flex w-full h-[50px] bg-gradient-to-r rounded-t-lg from-BASE_PURPLE to-BASE_BLUE">
                <div className="flex flex-row space-x-3 pl-5 items-center justify-center">
                    <img
                        className="w-5 h-4"
                        src={require("../../Assets/contact_logo.png")}
                        alt={"logo"}
                    />
                    <div className={"text-white font-normal text-lg"}>Contactez-nous</div>
                </div>
            </div>
            <div className={"flex-grow flex flex-col w-full px-5 py-5 space-y-3"}>
                <div className={"grid grid-cols-1 md:grid-cols-2 gap-4"}>
                    <CustomInput
                        onChange={(event) => setLastname(event.target.value)}
                        label="Nom"
                        required
                        value={lastname}
                    />
                    <CustomInput
                        onChange={(event) => setFirstname(event.target.value)}
                        label="Prénom"
                        required
                        value={firstname}
                    />
                </div>
                <div className={"grid grid-cols-1 md:grid-cols-2 gap-4"}>
                    <CustomInput
                        onChange={(event) => setCompany(event.target.value)}
                        label="Société"
                        required
                        value={company}
                    />
                    <CustomInput
                        onChange={(event) => setPhone(event.target.value)}
                        label="Téléphone"
                        required
                        value={phone}
                        type={"phone"}
                    />
                </div>
                <CustomInput
                    onChange={(event) => setMail(event.target.value)}
                    label="Adresse mail"
                    required
                    value={mail}
                    type={"email"}
                />
                <CustomSelect
                    options={subjectList}
                    handleChange={(value: string | number) => setMySubject(String(value))}
                    value={mySubject}
                    label={"Sujet"}
                />
                <div className={"flex flex-col h-2/6 space-y-2"}>
                    <div className={"text-gray-700 text-sm text-start"}>Message *</div>
                    <textarea
                        className="form-control
                                        block
                                        w-full
                                        h-full
                                        px-3
                                        py-1.5
                                        text-base
                                        font-normal
                                        text-gray-700
                                        bg-white bg-clip-padding
                                        border border-solid border-gray-300
                                        rounded
                                        transition
                                        ease-in-out
                                        m-0
                                        focus:text-gray-700 focus:bg-white focus:border-gray-800 focus:outline-none"
                        id="FormControlTextarea1"
                        value={message}
                        required
                        onChange={(event) => setMessage(event.target.value)}
                    />
                </div>
                <div className={"flex flex-col text-[10px] text-start"}>
                    <p>
                        Les informations qui vous concernent sont destinées à SECOIA. Vous
                        disposez d'un droit d'accès, de modification, de rectification et de
                        suppression des données qui vous concernent (art. 34 de la loi "Informatique et Libertés"). Pour
                        l'exercer, il vous suffit simplement de nous contacter.
                    </p>
                </div>
                <div className={"flex items-center justify-center"}>
                    <CustomButton text={"Envoyer"} type={"submit"} isLoading={isLoading}/>
                </div>
            </div>
        </form>
    );
}
