import { useUserContext } from "../../Dashboard/UserContext";
import userHaveRight from "../Utils/userHaveRight";

/* --- SCOPE --- */
export const SCOPE_MENU: string = "menu";
export const SCOPE_WINDOW: string = "fenetre";
export const SCOPE_TABLE: string = "table";

/* --- MENU --- */
export const MENU_HOME: string = "accueil";
export const MENU_ADMINISTRATION: string = "administration";
export const MENU_CONSOLIDATION: string = "consolidation";
export const MENU_EVALUATION: string = "evaluation";
export const MENU_SUPERVISION: string = "supervision";

/* --- WINDOW --- */
export const WINDOW_HOME: string = "accueil";
export const WINDOW_ADMINISTRATION: string = "administration";
export const WINDOW_CONSOLIDATION: string = "consolidation";
export const WINDOW_LIBRARY: string = "bibliotheque";
export const WINDOW_ACCOUNTING_STATEMENT: string = "etat_comptable";
export const WINDOW_WORKFORCE_RETIREMENT: string = "effectif_traite";
export const WINDOW_STUDY: string = "etude";
export const WINDOW_EVALUATION: string = "evaluation";
export const WINDOW_RESULT: string = "resultat";
export const WINDOW_SALARY: string = "salarie";
export const WINDOW_SUPERVISION: string = "supervision";
export const WINDOW_CONSOLIDATION_ACCOUNTING : string = "consolidation_etat_comptable";
export const WINDOW_CONSOLIDATION_SIMULATION : string = "simulation";

/* --- TABLE --- */
export const TABLE_CATEGORY: string = "categorie";
export const TABLE_COLLECTIVE_AGREEMENT: string = "convention_collective";
export const TABLE_COMPANY: string = "societe";
export const TABLE_USER: string = "utilisateur";
export const TABLE_FINANCIAL_PARAMETER: string = "parametre_financier";
export const TABLE_CAREER_END_ALLOWANCE: string = "indemnite_fin_carriere";
export const TABLE_MEDAL_GRATUITY: string = "gratification_medaille";
export const TABLE_CAREER_PROFILE: string = "profil_carriere";
export const TABLE_REGIME_RENT: string = "regime_rente";
export const TABLE_SURVIVAL: string = "survie";
export const TABLE_TURNOVER: string = "turnover";
export const TABLE_CONSOLIDATION_IFC_IFRS: string = "consolidation_ifc_ifrs";
export const TABLE_CONSOLIDATION_IFC_SOCIAL: string = "consolidation_ifc_social";
export const TABLE_CONSOLIDATION_MEDAILLE: string = "consolidation_medaille";
export const TABLE_STUDY: string = "etude";
export const TABLE_EVALUATION: string = "evaluation";
export const TABLE_ACCOUNTING_STATEMENT_IFC: string = "etat_comptable_ifc";
export const TABLE_ACCOUNTING_STATEMENT_MEDAILLE: string = "etat_comptable_medaille";
export const TABLE_EXPERIENCE_GAP: string = "ecart_actuariel_experience";
export const TABLE_CURRENT_INDIVIDUAL_GAP: string = "ecart_actuariel_individuel";
export const TABLE_COMMITMENT_SCHEDULE: string = "echeancier_engagement";
export const TABLE_CALCULATION_ERROR: string = "erreur_calcul";
export const TABLE_MOVEMENT_ERROR: string = "erreur_mouvement";
export const TABLE_RESULT_END_OF_CAREER_ALLOWANCE: string = "resultat_indemnite_fin_carriere";
export const TABLE_SIZE_RESULT: string = "resultat_medaille_detaille";
export const TABLE_RESULT_MEDAILLE: string = "resultat_medaille";
export const TABLE_MOVEMENT_CUMULE: string = "mouvement_cumule";
export const TABLE_MOVEMENT_INDIVIDUAL: string = "mouvement_individuel";
export const TABLE_BENEFIT_FUTURE: string = "prestation_future";
export const TABLE_RESULT_CUMULE: string = "resultat_cumule";
export const TABLE_FINANCIAL_RATE_SENSITIVITY: string = "sensibilite_taux_financier";
export const TABLE_SALARY: string = "salarie";
export const TABLE_SUPERVISION: string = "supervision";
export const TABLE_CURRENCY: string = "devise";
export const TABLE_MOVEMENT: string = "mouvement";
export const TABLE_CONTROL_CALCULATION: string = "controle_calcul";
export const TABLE_OPTION_CALCULATION: string = "option_calcul";
export const TABLE_FULL_SOCIAL_SECURITY_RATE: string = "taux_plein_securite_sociale";
export const TABLE_SOCIAL_SECURITY_CEILING: string = "plafond_securite_sociale";
export const TABLE_PERIMETER: string = "perimetre";
export const TABLE_USER_PROFILE: string = "profil_utilisateur";
export const TABLE_USER_RIGHT: string = "droit_utilisateur";
export const TABLE_CUSTOMER: string = "client";
export const TABLE_CATALOGUE: string = "catalogue";
export const TABLE_TYPE: string = "type";
export const TABLE_EMPLOYEE_IMPORT_FORMAT: string = "format_import_salarie";
export const TABLE_CALENDAR: string = "calendrier";
export const TABLE_EVENEMENT: string = "evenement";
export const TABLE_SIMULATION: string = "simulation";

/* -- RULES -- */
export const READ: string = "droit_lecture";
export const UPDATE: string = "droit_modification";
export const ADD: string = "droit_ajout";
export const ARCHIVING: string = "droit_archivage";
export const DELETE: string = "droit_suppression";
export const EXPORT: string = "droit_exportation";

export default function AccessControl({children, scope, section, right}: any)
{
    if (userHaveRight(useUserContext()?.permissions.droits.data, scope, section, right))
        return children;
    return null;
}
