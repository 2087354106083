import ContactUsForm from "./ContactUsForm";

export default function Support() {
    return (
        <div className="flex flex-col w-full font-worksans pb-10 px-4 md:px-10">
            <div className="flex flex-col w-full h-full items-start pt-5 space-y-10">
                <div className="text-2xl text-gray-800 font-semibold">Informations</div>
                <div className="flex flex-col md:flex-row w-full gap-4">
                    <div className="bg-white rounded-lg shadow-md flex-1 overflow-y-auto">
                        <div
                            className="flex w-full h-[50px] bg-gradient-to-r rounded-t-lg from-BASE_PURPLE to-BASE_BLUE">
                            <div className="flex flex-row space-x-3 pl-5 items-center justify-center">
                                <img
                                    className="w-5 h-5"
                                    src={require("../../Assets/call-help.png")}
                                    alt={"logo"}
                                />
                                <div className="text-white font-normal text-lg">Assistance client</div>
                            </div>
                        </div>
                        <div className="flex flex-col p-4 md:p-10 space-y-4">
                            <div className={"flex flex-col space-y-1 text-start text-gray-700 text-base"}>
                                <p>
                                    Le prestataire assure une permanence de 9 h à 12 h et de 14 h à 18 h du lundi au vendredi.
                                    <br />
                                    En dehors de ces horaires, le prestataire peut être joignable par courrier électronique ou par l'intermédiaire de son site Internet.
                                    <br />
                                    {process.env.REACT_APP_DEMO === '1'
                                        ? <>
                                            {'\u2022'} Courriel :
                                            <a
                                                href="mailto:supportlea2@secoia.fr"
                                                target="_blank"
                                                rel="noreferrer"
                                                className="hover:underline text-MAIN_BLUE pl-2"
                                            >
                                                supportlea2@secoia.fr
                                            </a>
                                        </>
                                        : <>
                                            {'\u2022'} Courriel :
                                            <a
                                                href="mailto:supportvincilea2@secoia.fr"
                                                target="_blank"
                                                rel="noreferrer"
                                                className="hover:underline text-MAIN_BLUE pl-2"
                                            >
                                                supportvincilea2@secoia.fr
                                            </a>
                                        </>
                                    }
                                    <br />
                                    {'\u2022'} Site Web : <a href="https://secoia.fr" target="_blank" rel="noreferrer" className="hover:underline text-MAIN_BLUE pl-2">www.secoia.fr</a>
                                    <br/>
                                    {/*{'\u2022'} Téléphone : <a href={`tel:${process.env.REACT_APP_SUPPORT_PHONE_NUMBER}`}  className="hover:underline text-MAIN_BLUE pl-2">{`${process.env.REACT_APP_SUPPORT_PHONE_NUMBER}`}</a>*/}
                                </p>
                            </div>
                            <div className={"flex flex-col space-y-1 text-start text-gray-700 text-base"}>
                                <p>
                                    Lors d'un appel à l'assistance, vous êtes en contact avec des consultants ou des
                                    techniciens qui maîtrisent l'intégralité de l'outil et qui sont en mesure de vous fournir des réponses immédiates et
                                    pertinentes.
                                    <br />
                                    Le prestataire s'engage à vous fournir une réponse dans les plus brefs délais.
                                </p>
                            </div>
                            <div className={"flex flex-col space-y-1 text-start text-gray-700 text-base"}>
                                <p>
                                    Le service d'assistance est assuré en interne afin d'apporter à nos clients un
                                    soutien
                                    toujours disponible.
                                    <br />
                                    Le prestataire s'engage à proposer des solutions qui correspondent aux besoins des clients
                                    afin
                                    de toujours garantir aux clients une qualité de conseils.
                                </p>
                            </div>
                            <div className={"flex flex-col space-y-1 text-start text-gray-700 text-base"}>
                                <p>
                                    Configuration du poste client :
                                    <br />
                                    {'\u2022'} Tous les ordinateurs sont acceptés (PC ou Mac)
                                    <br />
                                    {'\u2022'} Tous les systèmes d'exploitation sont supportés (Windows, Mac OS,
                                    Linux...)
                                    <br />
                                    {'\u2022'} Compatibilité avec tous navigateurs Internet récents (Firefox, Chrome,
                                    Opera, Edge)
                                    <br />
                                    {'\u2022'} Configuration navigateur : JavaScript activé, fenêtres Pop-up acceptées
                                    <br />
                                    {'\u2022'} Configuration minimale : processeur 2 Ghz et 8 Go de mémoire vive
                                    <br />
                                    {'\u2022'} Données externes (import / export) : Éditeur Texte, ® OpenOffice Calc ou ® Microsoft Excel
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="hidden md:block md:w-3/5 lg:w-2/5">
                        <ContactUsForm/>
                    </div>
                </div>
                <div className="block md:hidden">
                    <ContactUsForm/>
                </div>
            </div>
        </div>
    );
}
