import { configureStore } from "@reduxjs/toolkit";
import type { AnyAction } from "@reduxjs/toolkit";

export type InitialState = {
    isPageChangeAllowed: boolean;
    sizePerimeter: boolean;
    reloadPerimeter: boolean;
    perimeter: boolean;
};

const initialState: InitialState = {
    isPageChangeAllowed: true,
    sizePerimeter: false,
    reloadPerimeter: false,
    perimeter: false,
};

const storeReducer = (state: InitialState = initialState, action: AnyAction): InitialState => {
    switch (action.type) {
        case "ALLOW_PAGE_CHANGE":
            return { ...state, isPageChangeAllowed: true };
        case "DISALLOW_PAGE_CHANGE":
            return { ...state, isPageChangeAllowed: false };
        case "RESET_PERIMETER":
            return { ...state, reloadPerimeter: !state.reloadPerimeter };
        case "LOAD_PERIMETER":
            return { ...state, perimeter: !state.perimeter };
        case "SIZE_PERIMETER":
            return { ...state, sizePerimeter: action.sizePerimeter };
        default:
            return state;
    }
};

const store = configureStore({ reducer: storeReducer });

export default store;
