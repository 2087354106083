import React, {useEffect, useState, useRef, MutableRefObject } from "react";
import MainLoader from "../Feedback/MainLoader";
import ScrollContext from "../../Tools/context/scrollContext";

interface CustomRouteProps {
    setDefaultRoute: any,
    app: any,
    newRoute: string,
    isConnected?: boolean,
}

export default function CustomRoute({setDefaultRoute, app, newRoute, isConnected = true}: CustomRouteProps) {
    const [scroll, setScroll] = useState<number>(0);
    const [hasConnected, setHasConnected] = useState<boolean>(false);
    const scrollableDiv: MutableRefObject<HTMLDivElement | null> = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        setDefaultRoute(newRoute);
    }, [setDefaultRoute, newRoute]);

    useEffect(() => {
        if (isConnected)
            setHasConnected(true);
    }, [isConnected]);

    return (
        !isConnected && !hasConnected
            ? <MainLoader />
            : <ScrollContext.Provider value={scroll}>
                <div
                    ref={scrollableDiv}
                    style={{ height: '100%', overflow: 'auto' }}
                    onScroll={(e: React.UIEvent<HTMLElement>) => {
                        const el = e.target as HTMLElement;
                        const _el = scrollableDiv.current as HTMLElement;
                        const { scrollHeight, offsetHeight } = _el;
                        const { scrollTop } = el;

                        setScroll( (scrollHeight - offsetHeight) - scrollTop);
                    }}
                >
                    {app}
                </div>
            </ScrollContext.Provider>
    );
}