import React from "react";
import {CircularProgress, Paper, Typography} from "@mui/material";

export default function MainLoader() {

    return <div style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }}>
        <Paper variant={"outlined"} style={{
            width: '200px',
            display: "flex",
            flexDirection: 'column',
            justifyContent: "center",
            alignItems: "center",
            padding: 15
        }}>
            <CircularProgress style={{marginBottom: 5}}/>
            <Typography variant={"caption"} color={"textSecondary"}>Chargement en cours</Typography>
        </Paper>
    </div>;
}