type Callback = (rowId: number | null) => void;

const listeners: Callback[] = [];

const DataTableHoverBus = {
    onHover: (callback: Callback) => {
        listeners.push(callback);
    },
    emitHover: (rowId: number | null) => {
        listeners.forEach((callback) => callback(rowId));
    },
};

export default DataTableHoverBus;