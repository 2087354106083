import type { Perimeter } from "./types";

export const DEFAULT_PERIMETER: Perimeter<never, never> = {
    pole: [],
    division: [],
    direction_operationnelle: [],
    entite_juridique: [],
    id_import: null,
    date_import: null
};
