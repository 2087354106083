export default function LegalNotice() {
    return (
        <div className={"flex flex-col h-full w-full font-worksans"}>
            <div className={"flex flex-col h-full w-full items-start p-10 space-y-10"}>
                <div
                    className={
                        "text-2xl text-gray-800 font-semibold"
                    }
                    style={{fontSize: "clamp(1.5rem, 2.5vw, 2rem)"}}
                >
                    Mentions légales
                </div>
                <div className={"flex flex-col space-y-1 text-start text-gray-600 font-normal"}>
                    <p
                        className={"text-MAIN_BLUE text-lg font-semibold"}
                        style={{fontSize: "clamp(1rem, 1.5vw, 1.25rem)"}}
                    >
                        Éditeur
                    </p>
                    <p style={{fontSize: "clamp(0.875rem, 1.2vw, 1rem)"}}>
                        SECOIA :
                    </p>
                    <p style={{fontSize: "clamp(0.875rem, 1.2vw, 1rem)"}}>
                        S.A.S. au capital de 122 000 € - RCS 389 871 344 Paris
                    </p>
                    <p style={{fontSize: "clamp(0.875rem, 1.2vw, 1rem)"}}>
                        Adresse postale : 18 rue de la pépinière, 75008 PARIS 8
                    </p>
                    <p style={{fontSize: "clamp(0.875rem, 1.2vw, 1rem)"}}>
                        Courriel : secoia@secoia.fr - Web : www.secoia.fr
                    </p>
                </div>
                <div className={"flex flex-col space-y-1 text-start text-gray-600 font-normal"}>
                    <p
                        className={"text-MAIN_BLUE text-lg font-semibold"}
                        style={{fontSize: "clamp(1rem, 1.5vw, 1.25rem)"}}
                    >
                        License et droits de reproduction
                    </p>
                    <p style={{fontSize: "clamp(0.875rem, 1.2vw, 1rem)"}}>
                        Droits de reproduction et de diffusion réservés © SECOIA 2023. Usage strictement personnel. Par
                        l'accès au site, SECOIA consent à l'utilisateur qui l'accepte une licence dans les présentes
                        conditions. La licence confère à l'utilisateur un droit d'usage privé, non collectif et non
                        exclusif, sur le contenu du site. Elle comprend le droit de reproduire pour stockage aux fins de
                        représentation sur écran monoposte et de reproduction, en un exemplaire, pour copie de
                        sauvegarde ou
                        tirage sur papier. Toute mise en réseau, toute rediffusion, sous quelque forme, même partielle,
                        est
                        donc interdite. Ce droit est personnel, il est réservé à l'usage exclusif et non collectif du
                        licencié. Il n'est transmissible en aucune manière. Tout autre usage est soumis à autorisation
                        préalable et expresse. La violation de ces dispositions impératives soumet le contrevenant, et
                        toutes personnes responsables, aux peines pénales et civiles prévues par la loi.
                    </p>
                </div>
                <div className={"flex flex-col text-start text-gray-600 font-normal"}>
                    <p
                        className={"text-MAIN_BLUE text-lg font-semibold pb-1"}
                        style={{fontSize: "clamp(1rem, 1.5vw, 1.25rem)"}}
                    >
                        Gestion des données personnelles
                    </p>
                    <p style={{fontSize: "clamp(0.875rem, 1.2vw, 1rem)"}}>
                        L'ensemble des données est confidentiel et reste l'entière propriété du client. Nous n'avons
                        aucun
                        droit de regard sur ces données.
                    </p>
                    <p style={{fontSize: "clamp(0.875rem, 1.2vw, 1rem)"}}>
                        Elles sont stockées sur des serveurs sécurisés et automatiquement cryptées et protégées par mot
                        de
                        passe.
                    </p>
                    <p style={{fontSize: "clamp(0.875rem, 1.2vw, 1rem)"}}>
                        Le client peut à tout moment les modifier à sa convenance ou les supprimer définitivement.
                    </p>
                    <p style={{fontSize: "clamp(0.875rem, 1.2vw, 1rem)"}}>
                        A la fin de votre session, tous les fichiers temporaires sont automatiquement supprimés.
                    </p>
                </div>
            </div>
        </div>
    );
}