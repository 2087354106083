const CONFIRM_MESSAGE = "Attention ! Vous allez quitter cette page sans sauvegarder, ce qui entraînera la perte de toutes vos données. Êtes-vous sûr de vouloir continuer ?";

export default function pageChangeProhibited(callback?: Function, beforeunload?: boolean)
{
    if (beforeunload) {
        const handler = (event: any) => {
            event.preventDefault();
            event.returnValue = "";
        };

        window.addEventListener("beforeunload", handler);
        return () => {
            window.removeEventListener("beforeunload", handler);
            if (callback)
                callback();
        };
    }
    if (window.confirm(CONFIRM_MESSAGE) && callback)
        callback();
    return (): void => {};
}
