import {Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@mui/material";
import CustomButton from "../../Action/Button/ButtonCustom";

interface DialogHelpProps {
    open: boolean,
    handleClose(): void,
    title: string,
    content: string,
}

export default function DialogHelp({open, handleClose, title, content}: DialogHelpProps) {
    return <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
            {title}
        </DialogTitle>
        <DialogContent>
            <Typography>{content}</Typography>
        </DialogContent>
        <DialogActions>
            <CustomButton text={"Retour"} onClick={handleClose} />
        </DialogActions>
    </Dialog>;
}