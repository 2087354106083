import React, { FormEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useHandleError } from "../../Tools/HandleError/HandleError";
import CustomButton from "../../Tools/Action/Button/ButtonCustom";
import axios from "axios";
import { Alert, Snackbar } from "@mui/material";

export default function MissingPassword() {
    const navigate = useNavigate();
    const [email, setEmail] = useState<string>("");
    const [isLoadingPass, setIsLoadingPass] = useState<boolean>(false);
    const [data, setData] = useState<any>(null);
    const [open, setOpen] = useState<boolean>(false);
    const handleError = useHandleError();

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
    const isEmailValid = (email: string) => emailRegex.test(email);

    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!isEmailValid(email)) {
            handleError(null, () => {
                setIsLoadingPass(false);
            }, "L'adresse email n'est pas valide.");
            return;
        }
        setIsLoadingPass(true);

        const source = axios.CancelToken.source();

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_LEA_API}/connect/reset-password`,
                { courriel: email }
            );

            setData(response.data);
            setIsLoadingPass(false);
            setOpen(true);
        } catch (err: any) {
            if (err.response) {
                handleError(err, () => {
                    setIsLoadingPass(false);
                }, err.response.data.message);
            }
        }
        return () => {
            source.cancel("MissingPassword.tsx: Canceled request");
        };
    };

    return <>
        <form onSubmit={handleSubmit} className={"py-10"}>
            <div
                className="flex flex-col shadow font-worksans bg-white w-[560px] h-[640px] justify-between items-center space-y-6 rounded-lg">
                <div
                    className={"flex w-full h-[92px] bg-gradient-to-r rounded-t-lg from-BASE_PURPLE to-BASE_BLUE"}>
                    <div className={"flex flex-col ml-[20%] lg:ml-[83px] items-start justify-center"}>
                        <div className={"text-white font-normal text-xl"}>Mot de passe oublié</div>
                    </div>
                </div>
                <div className={"flex flex-col w-2/3 space-y-7"}>
                    <div className={"text-lg w-full text-start"}>
                        <p>Nous vous enverrons un e-mail pour réinitialiser votre mot de passe.</p>
                    </div>
                    <div className={"flex flex-col space-y-4 w-full"}>
                        <div className={"flex flex-col space-y-1 items-start"}>
                            <div className={"text-lg ml-3"}>Adresse email</div>
                            <div className="relative w-full">
                                <div
                                    className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                                    <img src={require("../../../Assets/Identifiant.png")}
                                         className="w-5 h-7 mt-2 text-gray-500" alt={"icon"}/>
                                </div>
                                <input type="email" id="input-group-2" onChange={(e) => {
                                    setEmail(e.target.value);
                                }}
                                       className="bg-white border border-gray-300 text-gray-900 text-lg rounded-2xl focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5"
                                       placeholder={process.env.REACT_APP_DEMO === "1" ? "jean.dupont@entreprise.fr" : "jean.dupont@vinci.fr"} required/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"flex flex-col pb-10 justify-center items-center space-y-3"}>
                    <CustomButton isLoading={isLoadingPass} text={"Récupérer mon mot de passe"} type={"submit"}/>
                    <div className={"text-base font-base text-MAIN_BLUE hover:underline"}
                         onClick={() => navigate("../login")}>Connexion
                    </div>
                </div>
            </div>
        </form>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="success" sx={{width: '100%'}}>
                {data?.message}
            </Alert>
        </Snackbar>
    </>;
}