import type { JSX, MouseEvent, HTMLAttributeAnchorTarget } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import pageChangeProhibited from "../../PageChangeProhibited/pageChangeProhibited";

type AnchorButtonProps = {
    className: string;
    url: string;
    value: string;
    onClick?: () => void;
    target?: HTMLAttributeAnchorTarget;
    prevent?: boolean
};

/**
 * AnchorButton component\
 * It's a text button (`<a>`) that redirects to a given url\
 * If the target isn't "_blank" and user left clicks on button, it will use the react-router-dom hook `useNavigate` to redirect to the given url
 *
 * @param {string} className The className of the anchor
 * @param {string} url The url to redirect to
 * @param {string} value The text of the button
 * @param {() => void} onClick The function to execute on click (optional)
 * @param {HTMLAttributeAnchorTarget} target The target of the link (optional)
 *
 * @return {JSX.Element} The AnchorButton component
 */
export default function AnchorButton({
    className,
    url,
    value,
    onClick,
    target,
    prevent = false
}: AnchorButtonProps): JSX.Element {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const isPageChangeAllowed = useSelector((state: any) => state.isPageChangeAllowed);

    const nav = (): void => {
        dispatch({ type: "ALLOW_PAGE_CHANGE" });
        navigate(url);
    };

    return (
        <a
            className={className}
            href={url}
            target={target}
            onClick={(event: MouseEvent<HTMLAnchorElement, globalThis.MouseEvent>): void => {
                if (onClick) onClick();
                if (target === "_blank") return;

                event.preventDefault();
                event.stopPropagation();

                if (isPageChangeAllowed) nav();
                else pageChangeProhibited(nav);
            }}
        >
            {value}
        </a>
    );
}
