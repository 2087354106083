import React from "react";
import type { JSX } from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import CustomButton from "../../../Action/Button/ButtonCustom";

type MessageProps = {
    title: string;
    body: string;
}

type DialogErrorProps = {
    open: boolean;
    handleClose: () => void;
    multiple?: boolean;
    message?: MessageProps;
};

export default function DialogError({
    open,
    handleClose,
    multiple = false,
    message
}: DialogErrorProps): JSX.Element {
    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>
                {message?.title || "Vous ne pouvez pas accéder à cet élément."}
            </DialogTitle>

            <DialogContent className={"font-worksans"}>
                <p
                    dangerouslySetInnerHTML={{
                        __html: message?.body || `Veuillez sélectionner <strong>${multiple ? "un ou plusieurs" : "un"}</strong> élément${multiple ? "(s)" : ""} du tableau.`
                    }}
                />
            </DialogContent>

            <DialogActions>
                <CustomButton
                    text={"Fermer"}
                    onClick={handleClose}
                />
            </DialogActions>
        </Dialog>
    );
}