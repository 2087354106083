import { useEffect, KeyboardEvent } from "react";
import type { JSX } from "react";
import { TextField, IconButton } from "@mui/material";

type RenderValueProps = {
    isEdit: boolean;
    value: string;
    rowIndex: number;
    onEdit: (newVal: string) => void;
    toggleEditable: (rowIndex: number, shouldEdit: boolean) => void;
    type?: string;
};

type EditableTextFieldProps = {
    value: string;
    rowIndex: number;
    onEdit: (newVal: string) => void;
    toggleEditable: (rowIndex: number, shouldEdit: boolean) => void;
    currentlyEditing?: number | null;
    type?: string;
};

function RenderValue({ isEdit, value, rowIndex, onEdit, toggleEditable, type }: RenderValueProps): JSX.Element
{
    if (isEdit)
        return (
            <TextField
                inputProps={{autoComplete: 'off'}}
                type={type ?? "text"}
                value={value}
                onClick={(event: any) => event.stopPropagation()}
                onChange={(event: any) => onEdit(event.target.value)}
                size="small"
                onKeyUp={(event: KeyboardEvent<HTMLInputElement>): void => {
                    if (event.key === "Enter")
                        toggleEditable(rowIndex, false); // exit edit mode
                }}
                sx={{ width: "100%", border: "none" }}
            />
        );
    if (type === "date")
        return <>{new Date(value).toLocaleDateString()}</>;
    return <>{value}</>;
}

function EditableTextField({ value, rowIndex, onEdit, toggleEditable, currentlyEditing, type }: EditableTextFieldProps): JSX.Element
{
    const isEdit = currentlyEditing === rowIndex;

    useEffect(() => {
        if (!isEdit && currentlyEditing === rowIndex)
            toggleEditable(rowIndex, false);
    }, [isEdit, currentlyEditing, rowIndex, toggleEditable]);

    return (
        <div className={"flex flex-row space-x-2 items-center justify-center text-center w-full h-full font-normal"}>
            <RenderValue
                isEdit={isEdit}
                value={value}
                rowIndex={rowIndex}
                onEdit={onEdit}
                toggleEditable={toggleEditable}
                type={type}
            />
            <IconButton onClick={(event: any) => {
                event.stopPropagation();
                toggleEditable(rowIndex, currentlyEditing !== rowIndex ?? false);
            }}>
                <svg
                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                    stroke="currentColor" className="w-4 h-4">
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125"/>
                </svg>
            </IconButton>
        </div>
    );
}

export default function renderEditableTextField(
    fieldKey: string,
    rowIndex: number,
    value: string,
    currentlyEditing: number | null,
    handleInputChange: (rowIndex: number, key: string, value: string) => void,
    toggleEditable: (rowIndex: number, shouldEdit: boolean) => void,
    type?: string
): JSX.Element
{
    return (
        <td className={"text-center bg-transparent h-full"}>
            <EditableTextField
                value={value}
                rowIndex={rowIndex}
                onEdit={(newVal: string) => handleInputChange(rowIndex, fieldKey, newVal)}
                toggleEditable={toggleEditable}
                currentlyEditing={currentlyEditing}
                type={type}
            />
        </td>
    );
}
