import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Snackbar } from "@mui/material";

const HandleErrorContext = React.createContext((err: any, callback: any, message: string = "Une erreur s'est produite.", needRedirect: boolean = false) => {
});

export default function HandleError({children}: any) {
    const [errorMessage, setErrorMessage] = useState<string|string[]>("Une erreur s'est produite.");
    const [openError, setOpenError] = useState(false);
    const navigate = useNavigate();

    const handleError = useCallback((err: any, callback: any, message: string = "Une erreur s'est produite.", needRedirect: boolean = false) => {
        if (axios.isCancel(err))
            return;
        setErrorMessage(message ? message : "Une erreur s'est produite.");
        setOpenError(true);

        if (needRedirect)
            navigate('./error');
        if (!!callback)
            callback();
    }, [navigate]);

    return <HandleErrorContext.Provider value={handleError}>
        {children}
        {!!errorMessage && <Snackbar anchorOrigin={{vertical: 'bottom', horizontal: 'left'}} autoHideDuration={6000} open={openError}  onClose={() => {
            setOpenError(false);
            setErrorMessage('');
        }}>
            <div className="flex p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800"
                 role="alert">
                <svg aria-hidden="true" className="flex-shrink-0 inline w-5 h-5 mr-3" fill="currentColor"
                     viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd"
                          d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                          clipRule="evenodd"></path>
                </svg>
                <span className="sr-only">Info</span>
                <div>
                    {
                        typeof errorMessage === "object" ?
                            errorMessage.map((err, index) => <p key={index}>{err}</p>) : errorMessage
                    }
                </div>
            </div>
        </Snackbar>}
    </HandleErrorContext.Provider>;
}

export const useHandleError = () => React.useContext(HandleErrorContext);
