import React, {JSX, useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import { useHandleError } from "../../Tools/HandleError/HandleError";
import CustomButton from "../../Tools/Action/Button/ButtonCustom";
import * as request from "../../Tools/Utils/APIRequests/request";
import type {StateProps} from "../../../types";
import {FormatPerimeter} from "../../Dashboard/Evaluation/Library/ToolsLibrary/PerimeterSelection";
import {DEFAULT_PERIMETER} from "../../../constants";
import type {RangeDirection, RangeDivision, RangeLegalEntity, RangePole} from "../../Dashboard/FilterBar";
import {useDispatch} from "react-redux";

interface SSOLoginProps {
    handleChangeConnected(newValue: boolean): any
}

export default function SSOLogin({
    handleChangeConnected
}: SSOLoginProps): JSX.Element {
    const navigateTo: string = "../../dashboard/home";
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const handleError = useHandleError();
    const dispatch = useDispatch();
    const [perimeter, setPerimeter]: StateProps<FormatPerimeter> = useState<FormatPerimeter>(JSON.parse(localStorage.getItem("PERIMETER") ?? "null")?.perimeter ?? DEFAULT_PERIMETER);

    useEffect((): void => {
        handleChangeConnected(false);

        if (localStorage.getItem("JWT")) return;

        const token = window.location.href.split("=");

        // @ts-ignore
        if (token && token.length === 2) {
            // @ts-ignore
            if (token[1][token[1].length - 1] === "#") {
                token[1] = token[1].slice(0, -1);
            }

            localStorage.setItem('JWT', token[1]);
            handleChangeConnected(true);
            navigate(navigateTo);
        }
    }, [handleChangeConnected, navigate, navigateTo]);

    async function onSubmit(event: any): Promise<void> {
        event.preventDefault();

        try {
            setIsLoading(true);

            await (async (): Promise<void> => {
                const req: request.Request = await request.get(`${process.env.REACT_APP_LEA_API}/user/me/perimetre`);

                if (req.isSuccessful) {
                    const pole = req.response.data.data.poles.map((item: RangePole) => ({
                        value: item.value,
                        id: item.value,
                        code: item.code_societe,
                        label: `${item.code_societe} - ${item.name}`,
                        nom: item.name
                    }));
                    const division = req.response.data.data.poles.flatMap((pole: RangePole) => (
                        pole.divisions.map((item: RangeDivision) => ({
                            value: item.value,
                            id: item.value,
                            code: item.code_societe,
                            label: `${item.code_societe} - ${item.name}`,
                            nom: item.name
                        }))
                    ));
                    const direction_operationnelle = req.response.data.data.poles.flatMap((pole: RangePole) => (
                        pole.divisions.flatMap((division: RangeDivision) =>
                            division.directions_operationnelles.map((item: RangeDirection) => ({
                                value: item.value,
                                id: item.value,
                                code: item.code_societe,
                                label: `${item.code_societe} - ${item.name}`,
                                nom: item.name
                            }))
                        )
                    ));
                    const entite_juridique = req.response.data.data.poles.flatMap((pole: RangePole) => (
                        pole.divisions.flatMap((division: RangeDivision) =>
                            division.directions_operationnelles.flatMap((direction: RangeDirection) =>
                                direction.entites_juridiques.map((item: RangeLegalEntity) => ({
                                    value: item.value,
                                    id: item.value,
                                    code: item.code_societe,
                                    label: `${item.code_societe} - ${item.name}`,
                                    nom: item.name
                                }))
                            )
                        )
                    ));

                    setPerimeter({
                        ...perimeter,
                        pole: pole,
                        division: division,
                        direction_operationnelle: direction_operationnelle,
                        entite_juridique: entite_juridique
                    });

                    // Fix date format with milliseconds
                    let formattedDate: number | null = perimeter.date_import;

                    if (formattedDate && formattedDate.toString().length > 10) {
                        formattedDate = formattedDate / 1000;
                    }

                    localStorage.setItem("PERIMETER", JSON.stringify({
                        perimeter: {
                            ...perimeter,
                            pole: pole,
                            division: division,
                            direction_operationnelle: direction_operationnelle,
                            entite_juridique: entite_juridique
                        },
                        rawPerimeter: {
                            pole: pole.map((item: RangePole) => item.value),
                            division: division.map((item: RangeDivision) => item.value),
                            direction_operationnelle: direction_operationnelle.map((item: RangeDirection) => item.value),
                            entite_juridique: entite_juridique.map((item: RangeLegalEntity) => item.value),
                            id_import: perimeter.id_import?.value ?? null,
                            date_import: formattedDate
                        }
                    }));
                } else if (req.isSessionExpired) {
                    handleError(
                        req.error,
                        (): void => {},
                        req.error?.response?.data?.message ?? request.EXPIRED_SESSION_MESSAGE,
                        true
                    );

                    dispatch({type: "RESET_PERIMETER"});
                } else if (req.error.response) {
                    handleError(
                        req.error,
                        (): void => {},
                        req.error.response.data.message
                    );
                }
            })();

            handleChangeConnected(true);
            navigate(navigateTo);
            setIsLoading(false);
        } catch (err: any) {
            if (err.response) {
                handleError(err, (): void => {
                    setIsLoading(false);
                }, err.response.data.message ?? "Impossible de se connecter.");
            }
        }
    }

    return (
        <form onSubmit={onSubmit} className={"py-5"}>
            <div
                className="flex flex-col font-worksans bg-white shadow w-[560px] justify-center items-center space-y-12 rounded-lg">
                <div className={"flex w-full h-[92px] bg-gradient-to-r rounded-t-lg from-BASE_PURPLE to-BASE_BLUE"}>
                    <div className={"flex flex-col ml-[20%] lg:ml-[83px] items-start justify-center"}>
                        <div className={"text-white font-normal text-xl"}>Connexion</div>
                        <div className={"text-white font-normal text-xs"}>Version 1.2.0</div>
                    </div>
                </div>

                <div className={"flex flex-col w-2/3 space-y-7"}>
                    <div className={"text-lg w-full text-start"}>
                        <p>Pour vous connecter, veuillez cliquer sur le bouton "Connexion", vous serez redirigé vers la
                            page d'authentification unique.</p>
                    </div>

                    <div className={"flex flex-col space-y-4 w-full"}>
                        <div className={"flex flex-col space-y-1 items-start"}>
                            <div className={"text-lg ml-3"}>
                                Groupe
                            </div>

                            <div className="relative w-full">
                                <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                                    <img src={require("../../../Assets/Group 166.png")}
                                         className="w-5 h-5 text-gray-500" alt={"icon"}/>
                                </div>

                                <input
                                    disabled
                                    type="text"
                                    id="input-group-1"
                                    className="bg-gray-200 border border-gray-300 text-gray-900 text-lg rounded-2xl focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5"
                                    placeholder="VINCI"
                                    required
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"flex flex-row space-x-5 pt-2 justify-center items-center"}>
                    <CustomButton isLoading={isLoading} text={"Connexion"}
                                  link={`${process.env.REACT_APP_LEA_API}/connect/microsoft`}/>
                </div>
                <div className={"flex flex-row justify-center items-center space-x-10 pb-5 text-sm"}>
                    <div className={"text-MAIN_BLUE hover:underline cursor-pointer"}
                         onClick={() => navigate("../unknown-identifier")}>Problème de connexion ?
                    </div>
                </div>
            </div>
        </form>
    );
};