
const isDEMOEnabled: boolean = process.env.REACT_APP_DEMO === '1';

export default function UnknownIdentifier() {
    return <div className={"py-10"}>
        <div
            className="flex flex-col shadow font-worksans bg-white w-[563px] h-[641px] items-center space-y-6 rounded-lg">
            <div
                className={"flex w-full h-[92px] bg-gradient-to-r rounded-t-lg from-BASE_PURPLE to-BASE_BLUE"}>
                <div className={"flex flex-col ml-[20%] lg:ml-[83px] items-start justify-center"}>
                    <div className={"text-white font-normal text-xl"}>Problème de connexion</div>
                </div>
            </div>
            <div className={"flex text-lg w-2/3 h-2/3 items-center text-center"}>
                {(!isDEMOEnabled) ?
                    <p>Merci de contacter votre référent pour paramétrer le compte.</p> :
                    <p>Merci de contacter votre référent {process.env.REACT_APP_DEMO === "1" ? "Secoia" : "Vinci"} pour paramétrer le compte.</p>
                }
            </div>
        </div>
    </div>;
}