import {
    MENU_ADMINISTRATION,
    MENU_CONSOLIDATION,
    MENU_EVALUATION,
    MENU_HOME,
    MENU_SUPERVISION,
    WINDOW_ADMINISTRATION, WINDOW_CONSOLIDATION, WINDOW_CONSOLIDATION_SIMULATION,
    WINDOW_LIBRARY,
    WINDOW_RESULT,
    WINDOW_SALARY,
    WINDOW_STUDY
} from "../Components/Tools/AccessControl/AccessControl";

export const HOME = 'home';

export const ADMINISTRATION = 'administration';
export const ADMIN_USER = 'user';
export const ADMIN_COMPANY = 'company';
export const ADMIN_AGREEMENT = 'agreement';
export const ADMIN_CATEGORY = 'category';
export const ADMIN_PROFILE = 'profile';

export const EVALUATION = 'evaluation';
export const EVAL_EMPLOYEES = 'employees';
export const EVAL_LIBRARY = 'library';
export const EVAL_STUDY = 'study';
export const EVAL_RESULT = 'result';

export const SUPERVISION = 'supervision';
export const CONSOLIDATION = 'consolidation';

// Ajoutez ces constantes pour les sous-menus
export const CONSO_CONSOLIDATION = 'consolidation';
export const CONSO_ETAT_COMPTABLE = 'accounting-statement';
export const CONSO_SIMULATION = 'simulation';

export const USER_GUIDE = "user-guide";
export const SUPPORT = "support";
export const LEGAL_NOTICE = "legal-notice";
export const PROFILE = "profile";
export const DISCONNECT = "disconnect";

export const modules = [
    {
        path: HOME,
        name: 'Accueil',
        rules: MENU_HOME,
        menu: [],
    },
    {
        path: ADMINISTRATION,
        name: 'Administration',
        rules: MENU_ADMINISTRATION,
        menu: [
            {
                path: ADMIN_COMPANY,
                rules: WINDOW_ADMINISTRATION,
                name: 'Sociétés'
            },
            {
                path: ADMIN_AGREEMENT,
                rules: WINDOW_ADMINISTRATION,
                name: 'Conventions'
            },
            {
                path: ADMIN_CATEGORY,
                rules: WINDOW_ADMINISTRATION,
                name: 'Catégories'
            },
            {
                path: ADMIN_USER,
                rules: WINDOW_ADMINISTRATION,
                name: 'Utilisateurs'
            },
            {
                path: ADMIN_PROFILE,
                rules: WINDOW_ADMINISTRATION,
                name: 'Profils'
            },
        ],
    },
    {
        path: EVALUATION,
        name: 'Evaluation',
        rules: MENU_EVALUATION,
        menu: [
            {
                path: EVAL_EMPLOYEES,
                rules: WINDOW_SALARY,
                name: 'Salariés'
            },
            {
                path: EVAL_LIBRARY,
                rules: WINDOW_LIBRARY,
                name: 'Bibliothèque'
            },
            {
                path: EVAL_STUDY,
                rules: WINDOW_STUDY,
                name: 'Etude'
            },
            {
                path: EVAL_RESULT,
                rules: WINDOW_RESULT,
                name: 'Résultats'
            },
        ],
    },
    {
        path: SUPERVISION,
        name: 'Supervision',
        rules: MENU_SUPERVISION,
        menu: [],
    },
    {
        path: CONSOLIDATION,
        name: 'Consolidation',
        rules: MENU_CONSOLIDATION,
        menu: [
            {
                path: CONSO_ETAT_COMPTABLE,
                rules: WINDOW_CONSOLIDATION,
                name: 'Consolidation - Etat comptable'
            },
            {
                path: CONSO_SIMULATION,
                rules: WINDOW_CONSOLIDATION_SIMULATION,
                name: 'Simulation'
            },
        ],
    }
];
