import type { JSX } from "react";

interface BoldNameProps {
    name: string;
    regex?: RegExp;
}

/**
 * Bold the name of a specific string ("name - date - user" (or the first part matching regex))
 *
 * @param {string} name The string in which to bold the name
 * @param {RegExp} regex The regex to match the name
 *
 * @return {JSX.Element} A paragraph element (<p>) containing bold name or the name if it doesn't match
 */
export default function BoldName({ name, regex = /(.+?) - \d{2}\/\d{2}\/\d{4} - / }: BoldNameProps): JSX.Element
{
    const match = name.match(regex);

    if (match)
        return <p><span className={"font-bold text-gray-800"}>{match[1]}</span>{name.slice(match[1].length)}</p>;
    return <p>{name}</p>;
}
