import type { JSX } from "react";
import { useNavigate } from "react-router-dom";
import CustomButton from "../Action/Button/ButtonCustom";

export default function ErrorPage(): JSX.Element
{
    const navigate = useNavigate();

    return (
        <div className={"flex h-full justify-center items-center flex-col"}>
            <p className="text-2xl">
                Une erreur est survenue
            </p>
            <CustomButton
                text={"Revenir sur la page précédente"}
                onClick={() => navigate("../")}
            />
        </div>
    );
}
