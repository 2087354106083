import {Route, Routes} from "react-router-dom";
import React from "react";
import ErrorNotFound from "../Feedback/ErrorNotFound";
import ErrorPage from "../Feedback/ErrorPage";

export default function CustomRouter({children} : any) {
    return <Routes>
        {children}
        <Route path={'/error'} element={<ErrorPage/>}/>
        <Route path={'/*'} element={<ErrorNotFound/>}/>
    </Routes>;
}