import React, { JSX, useEffect, useRef, useState, useReducer, useCallback, ChangeEvent, FormEvent, MutableRefObject } from "react";
import { useNavigate } from "react-router-dom";
import axios, { CancelTokenSource } from "axios";
import { useHandleError } from "../../Tools/HandleError/HandleError";
import CustomInput from "../../Tools/Action/Inputs/CustomInput";
import moment from "moment";
import MainLoader from "../../Tools/Feedback/MainLoader";
import isValidFrenchPhoneNumber from "../../Tools/Utils/isValidFrenchPhoneNumber";
import * as request from "../../Tools/Utils/APIRequests/request";

interface UserProfile {
    identifier: string;
    first_name: string;
    last_name: string;
    email: string;
    phone_number: string;
    country: string;
}

interface PerimeterProfils {
    company: string;
    pole: string;
    operational_management: string;
    division: string;
    affected_profil: string;
    start_of_rights: string;
    end_of_rights: string;
}

interface Action {
    type: string;
    key: string;
    value: string | any;
}

const userProfileReducer = (state: UserProfile, action: Action): any => {
    switch (action.type) {
        case "UPDATE":
            // @ts-ignore
            state[action.key] = action.value;

            return { ...state };

        case "UPDATE_ALL":
            state.identifier = action.value?.identifiant ? action.value?.identifiant : "";
            state.first_name = action.value?.prenom ? action.value?.prenom : "";
            state.last_name = action.value?.nom ? action.value?.nom : "";
            state.email = action.value?.courriel ? action.value?.courriel : "";
            state.phone_number = action.value?.telephone ? action.value?.telephone : "";
            state.country = action.value?.pays ? action.value?.pays : "";

            return { ...state };

        default:
            return state;
    }
};

const perimeterProfileReducer = (state: PerimeterProfils, action: Action): any => {
    switch (action.type) {
        case "UPDATE":
            // @ts-ignore
            state[action.key] = action.value;

            return { ...state };

        case "UPDATE_ALL":
            state.company = action.value?.societe?.nom ? action.value?.societe?.nom : "";
            state.pole = action.value?.societe?.nom_pole ? action.value?.societe?.nom_pole : "";
            state.operational_management = action.value?.societe?.nom_direction_operationnelle ? action.value?.societe?.nom_direction_operationnelle : "";
            state.division = action.value?.societe.nom_division ? action.value?.societe?.nom_division : "";
            state.affected_profil = action.value?.profil_utilisateur?.nom ? action.value?.profil_utilisateur?.nom : "";
            state.start_of_rights = action.value?.societe?.date_entree_societe ? moment(action.value?.societe?.date_entree_societe).format("DD/MM/YYYY") : "";
            state.end_of_rights = action.value?.societe?.date_sortie_societe ? moment(action.value?.societe?.date_sortie_societe).format("DD/MM/YYYY") : "";

            return { ...state };

        default:
            return state;
    }
};

export default function Profile (): JSX.Element {
    const [ isLoading, setIsLoading ] = useState<boolean>(false);
    const [ isErrorUser, setIsErrorUser ] = useState<boolean>(false);
    const [ isErrorFirstName, setIsErrorFirstName ] = useState<boolean>(false);
    const [ isErrorLastName, setIsErrorLastName ] = useState<boolean>(false);
    const [ isErrorEmail, setIsErrorEmail ] = useState<boolean>(false);
    const [ isErrorPhoneNumber, setIsErrorPhoneNumber ] = useState<boolean>(false);
    const isMounted: MutableRefObject<boolean | null> = useRef(null);
    const handleError: (err: any, callback: any, message?: string, needRedirect?: boolean) => void = useHandleError();
    const navigate: any = useNavigate();
    const navigateToDisconnect: () => void = useCallback(() => navigate("/dashboard/disconnect"), [ navigate ]);
    const [ userProfile, dispatch ] = useReducer(userProfileReducer,
        {
            identifier: "",
            last_name: "",
            first_name: "",
            email: "",
            phone_number: "",
            country: ""
        });
    const [ perimeterProfile, dispatchPerimeter ] = useReducer(perimeterProfileReducer,
        {
            company: "",
            pole: "",
            operational_management: "",
            division: "",
            affected_profil: "",
            start_of_rights: "",
            end_of_rights: ""
        });

    useEffect(() => {
        // @ts-ignore
        isMounted.current = true;

        const source: CancelTokenSource = axios.CancelToken.source();

        setIsLoading(true);

        (async (): Promise<void> => {
            const req: request.Request = await request.get(
                `${process.env.REACT_APP_LEA_API}/user/me`,
                undefined,
                source
            );

            if (req.isSuccessful) {
                if (isMounted && isMounted.current) {
                    dispatch({
                        type: "UPDATE_ALL",
                        value: req.response.data,
                        key: ""
                    });
                    dispatchPerimeter({
                        type: "UPDATE_ALL",
                        value: req.response.data,
                        key: ""
                    });
                    setIsLoading(false);
                }
            } else if (req.isSessionExpired) {
                handleError(
                    req.error,
                    (): void => {
                        setIsLoading(false);
                        navigateToDisconnect();
                    },
                    req.error?.response?.data?.message ?? request.EXPIRED_SESSION_MESSAGE,
                    true
                );
            } else if (req.error.response) {
                handleError(
                    req.error,
                    (): void => {
                        setIsLoading(false);
                    },
                    req.error.response.data.message
                );
            }
        })();

        return (): void => {
            // @ts-ignore
            isMounted.current = false;
            source.cancel("Profile");
        };
    }, [ handleError, navigateToDisconnect ]);

    const handleChange = (event: ChangeEvent<HTMLInputElement>, key: string): void => {
        if (isErrorUser || isErrorFirstName || isErrorLastName || isErrorEmail) {
            setIsErrorUser(false);
            setIsErrorFirstName(false);
            setIsErrorLastName(false);
            setIsErrorEmail(false);
        }
        dispatch({
            type: "UPDATE",
            value: event.target.value,
            key: key
        });
    };

    const handleChangePerimeter = (event: ChangeEvent<HTMLInputElement>, key: string): void => {
        dispatchPerimeter({
            type: "UPDATE",
            value: event.target.value,
            key: key
        });
    };

    const handleSubmitProfile = async (event: FormEvent<HTMLFormElement>) : Promise<void> => {
        event.preventDefault();

        if (userProfile.first_name === "" || userProfile.last_name === "" || userProfile.email === "" || userProfile.identifier === "") {
            handleError(
                null,
                (): void => {
                    if (!userProfile.identifier) setIsErrorUser(true);
                    if (!userProfile.email) setIsErrorEmail(true);
                    if (!userProfile.first_name) setIsErrorFirstName(true);
                    if (!userProfile.last_name) setIsErrorLastName(true);
                },
                "L'identifiant, le nom, le prénom et l'adresse email sont obligatoires."
            );

            return;
        }

        if (!isValidFrenchPhoneNumber(userProfile.phone_number)) {
            handleError(
                null,
                ():void => {
                    setIsErrorPhoneNumber(true);
                },
                "Le numéro de téléphone n'est pas valide."
            );

            return;
        }

        const source: CancelTokenSource = axios.CancelToken.source();
        const req: request.Request = await request.put(
            `${process.env.REACT_APP_LEA_API}/user/me/edit`,
            {
                identifiant: userProfile.identifier,
                nom: userProfile.last_name,
                prenom: userProfile.first_name,
                courriel: userProfile.email,
                telephone: userProfile.phone_number,
                pays: userProfile.country
            },
            undefined,
            source
        );

        if (req.isSessionExpired) {
            handleError(
                req.error,
                (): void => {
                    navigateToDisconnect();
                },
                req.error?.response?.data?.message ?? request.EXPIRED_SESSION_MESSAGE,
                true
            );
        }
    };

    return (
        <>
            {isLoading
                ? MainLoader()
                : (
                    <div className={"flex pb-10 2xl:pb-0 w-full text-worksans"}>
                        <div className={"flex flex-col w-full h-full items-start px-10 py-5 space-y-8"}>
                            <div className={"text-2xl text-gray-800 font-semibold"}>Compte utilisateur</div>

                            <div className={"flex flex-col w-full h-[300px] lg:h-[230px] bg-white rounded-lg shadow"}>
                                <div
                                    className={"flex w-full h-[50px] rounded-t-lg bg-gradient-to-r from-BASE_PURPLE to-BASE_BLUE"}
                                >
                                    <div className={"flex flex-row space-x-3 pl-5 items-center justify-center text-white"}>
                                        <svg
                                            xmlns={"http://www.w3.org/2000/svg"}
                                            className={"h-6 w-6"}
                                            fill={"none"}
                                            viewBox={"0 0 24 24"}
                                            stroke={"currentColor"}
                                            strokeWidth={2}
                                        >
                                            <path
                                                strokeLinecap={"round"}
                                                strokeLinejoin={"round"}
                                                d={"M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"}
                                            />
                                        </svg>

                                        <div className={"font-normal text-lg"}>Individu</div>
                                    </div>
                                </div>

                                <form
                                    className={"flex flex-row space-x-5 px-5 w-full h-[250px] lg:h-[180px] justify-center items-center"}
                                    onSubmit={handleSubmitProfile}
                                >
                                    <div className={"grid grid-cols-2 lg:grid-cols-3 gap-9 w-[90%] "}>
                                        <CustomInput
                                            label={"Identifiant :"}
                                            value={userProfile.identifier}
                                            error={isErrorUser}
                                            disabled={true}
                                            onChange={(e: any) => handleChange(e, "identifier")}
                                        />

                                        <CustomInput
                                            label={"Nom :"}
                                            value={userProfile.last_name}
                                            error={isErrorLastName}
                                            disabled={true}
                                            onChange={(e: any) => handleChange(e, "last_name")}
                                        />

                                        <CustomInput
                                            label={"Prénom :"}
                                            value={userProfile.first_name}
                                            error={isErrorFirstName}
                                            disabled={true}
                                            onChange={(e: any) => handleChange(e, "first_name")}
                                        />

                                        <CustomInput
                                            label={"Adresse mail :"}
                                            value={userProfile.email}
                                            error={isErrorEmail}
                                            disabled={true}
                                            onChange={(e: any) => handleChange(e, "email")}
                                        />

                                        <CustomInput
                                            label={"N° de téléphone :"}
                                            value={userProfile.phone_number}
                                            error={isErrorPhoneNumber}
                                            disabled={true}
                                            onChange={(e: any) => handleChange(e, "phone_number")}
                                        />

                                        <CustomInput
                                            label={"Pays :"}
                                            value={userProfile.country}
                                            disabled={true}
                                            onChange={(e: any) => handleChange(e, "country")}
                                        />
                                    </div>
                                </form>
                            </div>

                            <div
                                className={"flex flex-col w-full h-[360px] lg:h-[300px] 2xl:h-[250px] bg-white rounded-lg shadow"}
                            >
                                <div
                                    className={"flex w-full h-[50px] bg-gradient-to-r rounded-t-lg from-BASE_PURPLE to-BASE_BLUE"}
                                >
                                    <div className={"flex flex-row space-x-3 pl-5 items-center justify-center text-white"}>
                                        <svg
                                            xmlns={"http://www.w3.org/2000/svg"}
                                            className={"h-6 w-6"}
                                            fill={"none"}
                                            viewBox={"0 0 24 24"}
                                            stroke={"currentColor"}
                                            strokeWidth={2}
                                        >
                                            <path
                                                strokeLinecap={"round"}
                                                strokeLinejoin={"round"}
                                                d={"M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4"}
                                            />
                                        </svg>

                                        <div className={"font-normal text-lg"}>Société</div>
                                    </div>
                                </div>

                                <div
                                    className={"flex flex-col w-full h-[310px] lg:h-[250px] 2xl:h-[200px] justify-center px-5"}
                                >
                                    <div className={"grid grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-5 2xl:gap-10"}>
                                        <CustomInput
                                            readOnly={true}
                                            label={"Société :"}
                                            value={perimeterProfile.company}
                                            disabled={true}
                                            onChange={(e: any) => handleChangePerimeter(e, "company")}
                                        />

                                        <CustomInput
                                            readOnly={true}
                                            label={"Pôle :"}
                                            value={perimeterProfile.pole}
                                            disabled={true}
                                            onChange={(e: any) => handleChangePerimeter(e, "pole")}
                                        />

                                        <CustomInput
                                            readOnly={true}
                                            label={"Direction opérationnelle :"}
                                            value={perimeterProfile.operational_management}
                                            disabled={true}
                                            onChange={(e: any) => handleChangePerimeter(e, "operational_management")}
                                        />

                                        <CustomInput
                                            readOnly={true}
                                            label={"Division :"}
                                            value={perimeterProfile.division}
                                            disabled={true}
                                            onChange={(e: any) => handleChangePerimeter(e, "division")}
                                        />

                                        <CustomInput
                                            readOnly={true}
                                            label={"Profil affecté :"}
                                            value={perimeterProfile.affected_profil}
                                            disabled={true}
                                            onChange={(e: any) => handleChangePerimeter(e, "affected_profil")}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
        </>
    );
}