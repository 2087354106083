import React, { useEffect, useState, useCallback, useRef } from "react";
import type { HTMLAttributes, JSX, MutableRefObject } from "react";
import { Checkbox, TablePagination, IconButton } from "@mui/material";
import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";
import { TABLE_COMPANY } from "../AccessControl/AccessControl";
import ToolsBarTable from "./ToolsBarTable";
import MainLoader from "../Feedback/MainLoader";
import { Name } from "../../Dashboard/Evaluation/Result/ResultOther/TypeTable/TotalList";
import renderEditableTextField from "./TextFieldEditable";
import type { Pagination, StateProps } from "../../../types";
import NoData from "./NoData";
import { truncate } from "../Utils/utils";
import DataTableHoverBus from "../EventBus/DataTableHoverBus";

export interface TableColumnInterface {
    field: string,
    headerName: string,
    minWidth: number,
    flex: number,
    type: string,
    isEditable?: boolean,
    title?: string,
    className?: HTMLAttributes<HTMLTableCellElement>["className"];
    style?: HTMLAttributes<HTMLTableCellElement>["style"];
    exportValue?(data: any): string,
    valueGetter?(params: any): string,
    renderCell?(params: any): string,
}

function getNestedValue(obj: any, path: string) {
    return path.split('.').reduce((o, p) => {
        return o && o[p] ? o[p] : null;
    }, obj);
}

function filterEmptyColumns(columns: any[], rows: any[]) {
    return columns.filter((column) => {
        for (const row of rows) {
            const value = getNestedValue(row, column.field);

            if (value !== null && value !== undefined && value !== '') {
                return true;
            }
        }

        return false;
    });
}

type RenderRowCellProps = {
    row: any;
    index: number;
    col: TableColumnInterface;
    inBubble: boolean;
};

type RenderRowProps = {
    index: number;
    row: any;
    select: number[];
    columnsRendered: any[];
    isResultOther?: boolean;
    handleChangeChecked: (checked: boolean, id: number) => void;
    inBubble: boolean;
};

type BubblesProps = {
    rows: any[];
    columns: TableColumnInterface[];
    bubblesRef: MutableRefObject<HTMLDivElement | null>;
    inBubble: boolean;
    selectedRows?: number[];
}

function Bubbles({
    rows,
    columns,
    bubblesRef,
    inBubble,
    selectedRows
}: BubblesProps): JSX.Element {
    const [hoveredRow, setHoveredRow] = useState<number | null>(null);

    const handleMouseEnter = (rowId: number): void => {
        DataTableHoverBus.emitHover(rowId);
    };

    const handleMouseLeave = (): void => {
        DataTableHoverBus.emitHover(null);
    };

    useEffect(() => {
        const handleHover = (rowId: number | null): void => {
            setHoveredRow(rowId);
        };

        DataTableHoverBus.onHover(handleHover);

        return (): void => {
            // Cleanup listener when component unmounts
        };
    }, []);

    return (
        <div
            ref={bubblesRef}
            id={'bubblesContainer'}
            className={'overflow-auto pb-3 hidden min-w-max shadow-BUBBLES '}
            onMouseLeave={(): string | null => (bubblesRef.current ? bubblesRef.current.style.overflow = 'auto' : null)}
            onMouseEnter={(): string | null => (bubblesRef.current ? bubblesRef.current.style.overflow = 'hidden' : null)}
        >
            <table>
                <thead className={'sticky top-0 z-[100]'}>
                    <tr>
                        <th className={'h-[54px] bg-[#4A5BCB] text-white text-[14px] font-medium px-3'}>
                            {columns[0].headerName}
                        </th>
                    </tr>
                </thead>

                <tbody className={'border-r'}>
                    {rows.map((row: any, index: number): JSX.Element => (
                        <tr
                            key={`bubble-${index}`}
                            onMouseLeave={handleMouseLeave}
                            onMouseEnter={() => handleMouseEnter(row?.fake_id ?? row.id)}
                            className={`${selectedRows?.includes(row?.fake_id ?? row.id) || hoveredRow === (row?.fake_id ?? row.id) ? 'bg-[#4a5bcb42]' : ''}`}
                        >
                            <td className={`z-[1000] text-start font-normal text-[14px] border-b border-slate-100 whitespace-nowrap px-3 ${index === 0 ? 'h-[40.5px]' : 'h-[41px]'}`}>
                                <RenderRowCell
                                    row={row}
                                    index={index}
                                    col={columns[0]}
                                    inBubble={inBubble}
                                />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export function RenderRowCell({ row, index, col, inBubble }: RenderRowCellProps): JSX.Element {
    const [inputValue, setInputValue] = useState("");
    const [currentlyEditing, setCurrentlyEditing] = useState<number | null>(null);

    const getDataColFromRow = (column: TableColumnInterface, row: any): string => {
        if (column.renderCell !== undefined && column.renderCell !== null)
            return column.renderCell({row: row});
        else if (!!row[column.field])
            return row[column.field] as string;
        return "";
    };

    const handleInputChange = (_rowIndex: number, key: string, value: string) => {
        row[key] = value;
        setInputValue(value);
    };

    const toggleEditable = (rowIndex: number, shouldEdit: boolean) => {
        if (currentlyEditing && currentlyEditing !== rowIndex)
            toggleEditable(currentlyEditing, false);
        setCurrentlyEditing(shouldEdit ? rowIndex : null);
    };

    useEffect(() => setInputValue(row[col.field]), [row, col.field]);

    if (col.isEditable) {
        return renderEditableTextField(
            col.field,
            index,
            inputValue,
            currentlyEditing,
            handleInputChange,
            toggleEditable,
            col.type
        );
    } else {
        const value: string = getDataColFromRow(col, row);

        return inBubble
            ? (
                <span title={value}>
                    { truncate(value, 40) }
                </span>
            )
            : (
                <>
                    { value }
                </>
            )
        ;
    }
}

function RenderRow({
    index,
    row,
    select,
    columnsRendered,
    isResultOther,
    handleChangeChecked,
    inBubble
}: RenderRowProps): JSX.Element {
    const rowRef: MutableRefObject<HTMLTableRowElement | null> = useRef<HTMLTableRowElement | null>(null);
    const [hoveredRow, setHoveredRow] = useState<number | null>(null);

    const handleMouseEnter = (rowId: number): void => {
        DataTableHoverBus.emitHover(rowId);
    };

    const handleMouseLeave = (): void => {
        DataTableHoverBus.emitHover(null);
    };

    function is_number_align(col: TableColumnInterface){
        return (col.renderCell !== undefined && col.renderCell !== null && typeof(col.renderCell({row: row})) === 'string' ?
            (!isNaN(Number(col.renderCell({row: row}).replace(/\s+/g, ''))) ? "end" : "start") : "start");
    }

    function is_number_pos(col: TableColumnInterface){
        return (col.renderCell !== undefined && col.renderCell !== null && typeof(col.renderCell({row: row})) === 'string' ?
            (!isNaN(Number(col.renderCell({row: row}).replace(/\s+/g, ''))) ? col.minWidth + (col.headerName.length * 2) : 0) : 0);
    }

    const hasMatchingName = Object.values(row).some(value => {
        if (typeof value === 'string') {
            return Name.includes(value);
        }

        return false;
    });

    useEffect(() => {
        const handleHover = (rowId: number | null): void => {
            setHoveredRow(rowId);
        };

        DataTableHoverBus.onHover(handleHover);

        return (): void => {
            // Cleanup listener when component unmounts
        };
    }, []);

    return (
        <tr
            ref={rowRef}
            onMouseLeave={handleMouseLeave}
            onMouseEnter={() => handleMouseEnter(row.id)}
            key={`${index}-${row.fake_id ?? row.id}-table-body-rows`}
            onClick={() => handleChangeChecked(!select.includes(row?.fake_id ?? row.id), row.fake_id ?? row.id)}
            style={{ backgroundColor: hoveredRow === (row?.fake_id ?? row.id) || select.includes(row?.fake_id ?? row.id) ? 'rgba(74, 91, 203, 0.26)' : hasMatchingName && isResultOther ? '' : 'white' }}
            className={`${hasMatchingName && isResultOther ? `p-2 border border-grey-400 text-center text-base font-semibold antialiased bg-blue-50 bg-opacity-90 whitespace-no-wrap` : ""}`}
        >
            <td style={{ minWidth: 10, borderBottom: '1px solid whitesmoke' }}>
                <Checkbox
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChangeChecked(event.target.checked,row.fake_id ?? row.id)}
                    checked={select.includes(row.fake_id ?? row.id)}
                    size={'small'}
                />
            </td>

            {columnsRendered.map((col: TableColumnInterface, colNb: number) => {
                col.style = {
                    zIndex: 1000,
                    minWidth: col.minWidth + (col.headerName.length * 2),
                    fontWeight: hasMatchingName && isResultOther ? 800 : 400,
                    fontSize: 14,
                    whiteSpace: 'pre',
                    textAlign: is_number_align(col),
                    paddingRight: is_number_pos(col),
                    borderBottom: '1px solid whitesmoke',
                };

                return (
                    <td
                        key={`${col.headerName}-${colNb}-columns-table-body-pagination`}
                        className={col.className}
                        style={col.style}
                    >
                        <RenderRowCell row={row} index={index} col={col} inBubble={inBubble} />
                    </td>
                );
            })}
        </tr>
    );
}

interface RenderHeaderProps {
    rows: any[],
    columnsRendered: any[],
    setSelect: React.Dispatch<React.SetStateAction<number[]>>,
    select: number[],
    columns: TableColumnInterface[],
    sort: "asc" | "desc",
    onSelect?(ids: number[]): any,
    handleSort(column: TableColumnInterface): void,
    thRef: MutableRefObject<HTMLTableSectionElement | null>;
}

function RenderHeaderTable({
    rows,
    setSelect,
    onSelect = (_: number[]) => {},
    columnsRendered,
    select,
    sort,
    handleSort,
    thRef
}: RenderHeaderProps): JSX.Element {
    const handleAllChecked = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const checked = rows.map((elem: any) => elem.fake_id ?? elem.id);
            setSelect(checked);
            onSelect(checked);
        } else {
            setSelect([]);
            onSelect([]);
        }
    }, [onSelect, rows, setSelect]);

    return (
        <thead ref={thRef} className={'sticky top-0 z-[100]'}>
            <tr>
                <th className={'text-white py-[8px] min-w-[10px] border-[#4A5BCB] bg-[#4A5BCB]'}>
                    <Checkbox
                        onChange={handleAllChecked}
                        checked={select.length === rows.length}
                        size={'small'}
                        style={{color: 'white'}}
                    />
                </th>

                {columnsRendered.map((elem: TableColumnInterface, index: number) => {
                    const th = (elem: TableColumnInterface): JSX.Element => {
                        if (elem.title) {
                            const breaklineContent: string = elem.headerName.replace(elem.title, ``);

                            return (
                                <div className={'flex flex-row content-center gap-3'}>
                                    {elem.title} <br/>
                                    {breaklineContent.includes(' | ')
                                        ? breaklineContent.replace(' | ', '')
                                        : breaklineContent
                                    }

                                    <IconButton
                                        size={'small'}
                                        onClick={() => {
                                            handleSort(elem);
                                        }}
                                    >
                                        {sort === 'asc'
                                            ? <ArrowDropDown style={{color: 'white'}}/>
                                            : <ArrowDropUp style={{color: 'white'}}/>
                                        }
                                    </IconButton>
                                </div>
                            );
                        }

                        return (
                            <>
                                {elem.headerName}

                                <IconButton
                                    size={'small'}
                                    onClick={() => {
                                        handleSort(elem);
                                    }}
                                >
                                    {sort === 'asc'
                                        ? <ArrowDropDown style={{color: 'white'}}/>
                                        : <ArrowDropUp style={{color: 'white'}}/>
                                    }
                                </IconButton>
                            </>
                        );
                    };

                    return (
                        <th
                            key={`${elem.headerName}-${index}-columns-table-pagination`}
                            className={'text-white font-medium text-[14px] border-[#4A5BCB] bg-[#4A5BCB] text-left py-[10px] whitespace-nowrap'}
                        >
                            {th(elem)}
                        </th>
                    );
                })}
            </tr>
        </thead>
    );
}

interface TablePaginationProps {
    scrollColumnName?: string;
    columns: TableColumnInterface[],
    data: any[],
    rows: any[],
    isLoading: boolean,
    resetSearch?: boolean;
    origin: string,
    emptyColumns?: boolean,
    isUpdateDisable?: boolean,
    isDeleteDisable?: boolean,
    isRestoreDisable?: boolean,
    isLockDisable?: boolean,
    rolesSection?: string,
    exportUrl?: string | null,
    exportFilter?: any | null,
    pagination?: Pagination,
    askLoad?: boolean,
    isResultOther?: boolean,
    clearLoaded?: boolean,
    sortWithoutPagination?: boolean,
    handleClearLoaded?(): any,
    handleOpenCreate?(): any,
    handleOpenUpdate?(): any,
    handleOpenDelete?(): any,
    handleOpenReference?(): any,
    handleOpenRestore?(): any,
    handleFilterSearch?(data: any[]): any,
    onSelect?(ids: number[]): any,
    handleOpenLock?(): any,
    handleDuplicate?(name: string): any,
    handlePageChange?: (pageNumber: number, filter: string, resetData?: boolean, sort?: string) => void;
    handleSortChange?: (sort: string, collumnName: string) => void;
}

export default function TablePaginationPro({
    columns,
    data = [],
    isLoading = false,
    resetSearch = false,
    rows = [],
    origin = "document",
    handleOpenCreate = undefined,
    handleOpenUpdate = undefined,
    isUpdateDisable = false,
    handleOpenDelete = undefined,
    handleOpenReference = undefined,
    isDeleteDisable = false,
    handleOpenRestore = undefined,
    isRestoreDisable = false,
    handleFilterSearch = undefined,
    onSelect = (_: number[]): void => {},
    exportUrl = null,
    handleOpenLock = undefined,
    isLockDisable = false,
    handleDuplicate = undefined,
    rolesSection = TABLE_COMPANY,
    pagination = undefined,
    handlePageChange = undefined,
    emptyColumns = false,
    exportFilter = null,
    clearLoaded,
    handleClearLoaded,
    askLoad = false,
    isResultOther,
    sortWithoutPagination = true,
    handleSortChange = (sort: string, collumnName: string): void => {},
}: TablePaginationProps): JSX.Element {
    const [select, setSelect] = useState<number[]>([]);
    const [filter, setFilter] = useState<string>("");
    const [page, setPage] = useState<number>(pagination && pagination!.page_actuel ? pagination!.page_actuel - 1 : 0);
    const [loadedPage, setLoadedPage] = useState<number[]>([0]);
    const [renderedRows, setRenderedRows] = useState<any[]>([]);
    const [columnsRendered, setColumnsRendered] = useState<any[]>(columns);
    const [sort, setSort] = useState<"asc" | "desc">("asc");
    const nonEmptyColumns: any[] = filterEmptyColumns(columns, rows);
    const [tableScrollX, setTableScrollX]: StateProps<number> = useState<number>(0);
    const thRef = useRef<HTMLTableSectionElement | null>(null);
    const tbodyRef = useRef<HTMLTableSectionElement | null>(null);
    const divRef = useRef<HTMLDivElement | null>(null);
    const bubblesRef = useRef<HTMLDivElement | null>(null);
    const scrollTimeout = useRef<NodeJS.Timeout | null>(null);
    const [bubbleIsToggle, setBubbleIsToggle] = useState<boolean>(false);
//on ne pas rajoute de dépendance sinon on ne peut plus selectionné les lignes
    useEffect((): void => {
        setSelect([]);
        onSelect([]);

        if (clearLoaded === true) {
            setLoadedPage([0]);

            if (handleClearLoaded) handleClearLoaded();
        }
        //eslint-disable-next-line
    }, [isLoading, clearLoaded]);

    useEffect(() => setPage(pagination && pagination!.page_actuel ? pagination!.page_actuel - 1 : 0), [pagination]);

    useEffect(() => setFilter(""), [resetSearch]);

    const updateRenderedRows = useCallback(() => {
        // if (sortWithoutPagination) {
        //     let startIndex, endIndex;
        //
        //     if (pagination) {
        //         startIndex = page * pagination.par_page;
        //         endIndex = startIndex + pagination.par_page;
        //     } else {
        //         startIndex = page * 50;
        //         endIndex = startIndex + 50;
        //     }
        //
        //     const newRenderedRows = rows.slice(startIndex, endIndex);
        //     setRenderedRows(newRenderedRows);
        // }

        let startIndex, endIndex;

        if (pagination) {
            startIndex = page * pagination.par_page;
            endIndex = startIndex + pagination.par_page;
        } else {
            startIndex = page * 50;
            endIndex = startIndex + 50;
        }

        const newRenderedRows = rows.slice(startIndex, endIndex);
        setRenderedRows(newRenderedRows);

    }, [page, pagination, rows]);

    useEffect((): void => {
        updateRenderedRows();
    }, [rows, page]);

    useEffect((): void => {
        if (emptyColumns)
            setColumnsRendered(columns);
        else
            setColumnsRendered(nonEmptyColumns);
    }, [columns, emptyColumns]);

    const handleChangeFilter = (str: string) => {
        setFilter(str);
        if (!!handlePageChange) {
            setPage(0);
            setLoadedPage([0]);
            handlePageChange(1, str, true);
        }
    };

    const handleChangeChecked = useCallback((selected: boolean, id: number) => {
        if (selected) {
            const updatedSelect = [...select, id];
            setSelect(updatedSelect);
            onSelect(updatedSelect);
        } else {
            const updatedSelect = select.filter((elem: number) => elem !== id);
            setSelect(updatedSelect);
            onSelect(updatedSelect);
        }
    }, [onSelect, select]);

    const handleSort = useCallback((column: TableColumnInterface) => {
        setSort((prevSort) => (prevSort === "asc" ? "desc" : "asc"));

        if (sortWithoutPagination) {
            setRenderedRows((prevRenderedRows) => {
                return data.sort((a: any, b: any) => {
                    const getValue = column.valueGetter !== undefined && column.valueGetter !== null
                        ? column.valueGetter
                        : (row: any) => row[column.field];

                    const aVal = getValue({row: a});
                    const bVal = getValue({row: b});

                    if (aVal < bVal) return sort === "asc" ? -1 : 1;
                    if (aVal > bVal) return sort === "asc" ? 1 : -1;
                    return 0;
                });
            });
        } else {
            handleSortChange(sort, column.field);
        }
    }, [sortWithoutPagination, data, sort, handleSortChange]);

    const tableCellStyleUpdateOnScroll = useCallback((): void => {
        if (!bubblesRef.current) return;

        if (tableScrollX >= 38) {
            setBubbleIsToggle(true);
            bubblesRef.current.classList.remove('hidden');
        } else {
            setBubbleIsToggle(false);
            bubblesRef.current.classList.add('hidden');
        }
    }, [tableScrollX, bubblesRef]);

    const handleTableScroll = useCallback((e: React.UIEvent<HTMLElement>): void => {
        const bubbleTable: HTMLDivElement | null = bubblesRef.current;
        const el = e.target as HTMLElement;
        const { scrollLeft, scrollTop } = el;

        if (bubbleTable) bubbleTable.scrollTop = scrollTop;
        if (scrollTimeout.current) clearTimeout(scrollTimeout.current);

        scrollTimeout.current = setTimeout((): void => {
            setTableScrollX(scrollLeft);
            scrollTimeout.current = null;
        }, 200);
    }, [setTableScrollX]);

    useEffect((): void => {
        setTableScrollX(0);
    }, [setTableScrollX]);

    useEffect((): void => {
        tableCellStyleUpdateOnScroll();
    }, [tableCellStyleUpdateOnScroll, tableScrollX]);

    return (
        <div className={`flex w-full h-full shadow rounded-lg min-h-[400px]`}>
            <ToolsBarTable
                handleDuplicate={handleDuplicate}
                rolesSection={rolesSection}
                origin={origin}
                handleOpenRestore={handleOpenRestore}
                exportUrl={exportUrl}
                isRestoreDisable={isRestoreDisable}
                handleFilterSearch={handleFilterSearch}
                disableSearch={askLoad}
                isUpdateDisable={isUpdateDisable}
                resetSearch={resetSearch}
                handleOpenUpdate={handleOpenUpdate}
                isDeleteDisable={isDeleteDisable}
                data={data}
                columns={columns}
                select={select}
                handleOpenCreate={handleOpenCreate}
                handleOpenDelete={handleOpenDelete}
                handleOpenReference={handleOpenReference}
                handleOpenLock={handleOpenLock}
                isLockDisable={isLockDisable}
                handleChangeFilter={handleChangeFilter}
                filter={exportFilter}
            />


            {isLoading
                ? <div className={"flex h-full w-full justify-center items-center"}>
                    <MainLoader/>
                </div>
                : data.length === 0
                    ? <div className={'m-auto'}>
                        <NoData askLoad={askLoad}/>
                    </div>
                    : <div className={'flex flex-col'} style={{width: "calc(100% - 50px)"}}>
                        <div className={'flex flex-row'} style={{ maxHeight: "calc(100% - 50px)"}}>
                            <Bubbles
                                columns={columns}
                                rows={renderedRows}
                                bubblesRef={bubblesRef}
                                inBubble={bubbleIsToggle}
                                selectedRows={select}
                            />

                            <div className={'w-full flex overflow-auto z-[1]'} ref={divRef} onScroll={handleTableScroll}>
                                <table className={'w-full'}>
                                    <RenderHeaderTable
                                        key={`columns-FixedSizeList-Key`}
                                        rows={rows}
                                        columnsRendered={columnsRendered}
                                        setSelect={setSelect}
                                        select={select}
                                        columns={columns}
                                        handleSort={handleSort}
                                        sort={sort}
                                        onSelect={onSelect}
                                        thRef={thRef}
                                    />

                                    <tbody ref={tbodyRef}>
                                        {renderedRows.map((elem: any, index: number) => (
                                            <RenderRow
                                                key={`${index}-columns-FixedSizeList-Key`}
                                                index={index}
                                                row={elem}
                                                select={select}
                                                columnsRendered={columnsRendered}
                                                handleChangeChecked={handleChangeChecked}
                                                isResultOther={isResultOther}
                                                inBubble={bubbleIsToggle}
                                            />
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        {askLoad
                            ? <div style={{padding: 40}} className={"font-bold px-20"}>
                                Veuillez sélectionner au moins un périmètre et cliquer sur "Afficher"
                            </div>
                            : null
                        }

                        <div className={'flex bg-white mt-auto justify-between z-[1]'}>
                            <div className={'p-[12px]'}>
                                {select.length > 1
                                    ? `${select.length} lignes sélectionnées`
                                    : select.length === 1
                                        ? `${select.length} ligne sélectionnée`
                                        : "Aucune ligne sélectionnée"
                                }
                            </div>

                            <TablePagination
                                labelDisplayedRows={({from, to, count}: {
                                    from: number,
                                    to: number,
                                    count: number
                                }): string => `${from}-${to} sur ${count}`}
                                component="div"
                                count={!!pagination && !!pagination.total ? pagination?.total : rows.length}
                                rowsPerPage={!!pagination && !!pagination.par_page ? pagination?.par_page : 50}
                                rowsPerPageOptions={[!!pagination && !!pagination.par_page ? pagination?.par_page : 50]}
                                page={page}
                                onPageChange={(event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number): void => {
                                    setPage(newPage);

                                    if (!loadedPage.includes(newPage)) {
                                        if (handlePageChange) handlePageChange(newPage + 1, filter, false, sort);

                                        setLoadedPage((prevLoadedPage: number[]) => [...prevLoadedPage, newPage]);
                                    }

                                    if (divRef.current) divRef.current.scrollTop = 0;
                                }}
                            />
                        </div>
                    </div>
            }
        </div>
    );
}