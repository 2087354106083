import axios from "axios";

export default async function refreshToken(refresh?: string | null): Promise<{ result: boolean, error: any | null }>
{
    if (refresh === undefined)
        refresh = localStorage.getItem("REFRESH");
    try {
        const response = await axios.post(
            `${process.env.REACT_APP_LEA_API}/refresh-token`,
            { refresh_token: refresh }
        );

        localStorage.setItem("JWT", response.data.token);
        localStorage.setItem("REFRESH", response.data.refresh_token);
        localStorage.setItem("EXPIRATION", response.data.refresh_token_expiration);
    } catch (error) {
        return { result: false, error: error };
    }
    return { result: true, error: null };
}
