import post from "./post";
import get from "./get";
import put from "./put";
import del from "./delete";

export type Request = {
    isSuccessful: boolean;
    isSessionExpired: boolean;
    response: any | null;
    error: any | null;
};

export function setConfig(config: any, source: any): any
{
    config.cancelToken = source?.token;
    if (config.headers)
        config.headers.Authorization = `Bearer ${localStorage.getItem("JWT")}`;
    else
        config.headers = { Authorization: `Bearer ${localStorage.getItem("JWT")}` };
    return config;
}

export { post, get, put, del };

export const EXPIRED_SESSION_MESSAGE = "Votre session a expiré, veuillez vous reconnecter.";
