import { useState, useCallback } from "react";
import type { JSX } from "react";
import { useNavigate } from "react-router-dom";
import { Checkbox, DialogContent } from "@mui/material";
import type { GridRowId } from "@mui/x-data-grid";
import axios from "axios";
import { useHandleSuccess } from "../Tools/HandleSuccess/HandleSuccess";
import { useHandleError } from "../Tools/HandleError/HandleError";
import TablePaginationPro, { TableColumnInterface } from "../Tools/Display/TablePaginationPro";
import CustomButton from "../Tools/Action/Button/ButtonCustom";
import DialogImportSummary, { Success, Error } from "../Tools/Display/Dialog/DialogImportSummary";
import DialogEmptySelection from "../Tools/Display/Dialog/DialogHelp";
import * as request from "../Tools/Utils/APIRequests/request";

type TableProps = {
    file: File | undefined;
    isUploaded: boolean;
    isTableLoading: boolean;
    columns: TableColumnInterface[];
    data: any[];
    dataPath: string;
    rows: any[];

    handleFilterSearch(data: any[]): any;
};

type UpdatedTablesTable = {
    import: string;
    type_de_table: string;
    nom: string;
    date_modification: string;
};

type UpdatedTables = {
    chemin: string;
    societe_vers_etablissement: boolean;
    tables: UpdatedTablesTable[];
};

function responseDataToJSX(data: any, setImportSummary: any, error?: string): void {
    const importSummaryArray: JSX.Element[] = [];

    if (error)
        importSummaryArray.push(<Error text={error}/>);
    data.data.table_importee?.forEach((table: string) => {
        importSummaryArray.push(<Success text={table}/>);
    });
    data.data.table_deja_importee?.forEach((table: string) => {
        importSummaryArray.push(<Error text={table}/>);
    });
    setImportSummary(importSummaryArray);
}

// function AnomalyReport({anomaly}: { anomaly: boolean })
// {
//     if (!anomaly)
//         return null;
//     return <>
//         <h1 className={"flex h-full items-center text-gray-600 font-semibold"} style={{ marginTop: "1em", marginBottom: "0.25em" }}>Rapport d'anomalie</h1>
//         <p style={{ marginLeft: "30px" }}>
//             ANOMALIE
//         </p>
//     </>;
// }

/**
 * @hint isThereAnomaly is not used for now. It will be used to display the anomaly report (see Figma for more details).
 */
export default function Table({file, isUploaded, isTableLoading, columns, data, dataPath, rows, handleFilterSearch}: TableProps) {
    const [selectedRow, setSelectedRow] = useState<GridRowId[]>([]);
    // const [isThereAnomaly, setIsThereAnomaly] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState(false);
    const [openDialogImport, setOpenDialogImport] = useState<boolean>(false);
    const [openDialogEmptySelection, setOpenDialogEmptySelection] = useState<boolean>(false);
    const [dialogEmptyTitle, setDialogEmptyTitle] = useState<string>("");
    const [dialogEmptyContent, setDialogEmptyContent] = useState<string>("");
    const [importSummary, setImportSummary] = useState<JSX.Element[]>([]);
    const [companyToEstablishment, setCompanyToEstablishment] = useState<boolean>(false);

    const handleSuccess = useHandleSuccess();
    const handleError = useHandleError();
    const navigate = useNavigate();

    const navigateToDisconnect = useCallback(() => navigate("disconnect"), [navigate]);

    if (!isUploaded)
        return null;

    function handleCloseDialogImport() {
        setOpenDialogImport(false);
    }

    function handleCloseDialogEmptySelection() {
        setOpenDialogEmptySelection(false);
    }

    const importTables = async (): Promise<undefined | (() => void)> => {
        if (selectedRow.length === 0) {
            setDialogEmptyTitle("Aucune table sélectionnée");
            setDialogEmptyContent("Veuillez sélectionner au moins une table à importer.");
            setOpenDialogEmptySelection(true);
            return;
        }

        let isEmptyNewName: boolean = false;
        const emptyNewNames: string[] = [];
        let isInvalidDates: boolean = false;
        const invalidDates: string[] = [];
        const updatedTables: UpdatedTables = {
            chemin: dataPath,
            societe_vers_etablissement: companyToEstablishment,
            tables: data.filter(obj => selectedRow.includes(obj.id)).map(obj => {
                const { id, nouveau_nom, date_calcul, ...rest } = obj;
                const newName = nouveau_nom?.trim();
                const calculationDate = new Date(date_calcul).getTime() / 1000;

                if (newName === null || newName === "") {
                    isEmptyNewName = true;
                    emptyNewNames.push(rest.nom);
                }
                if (isNaN(calculationDate)) {
                    isInvalidDates = true;
                    invalidDates.push(rest.nom);
                }
                rest.import = "1";
                return {
                    ...rest,
                    nouveau_nom: newName,
                    date_calcul: calculationDate
                };
            })
        };

        if (isEmptyNewName) {
            setDialogEmptyTitle(emptyNewNames.length > 1 ?
                "Tables importées sans nouveau nom" :
                "Table importée sans nouveau nom");
            setDialogEmptyContent(`Veuillez entrer un nouveau nom pour ces tables : ${emptyNewNames.join(", ")}.`);
            setOpenDialogEmptySelection(true);
            return;
        }
        if (isInvalidDates) {
            setDialogEmptyTitle(invalidDates.length > 1 ?
                "Tables importées avec des dates de calcul invalides" :
                "Table importée avec une date de calcul invalide");
            setDialogEmptyContent(`Veuillez entrer une date de calcul valide pour ces tables : ${invalidDates.join(", ")}.`);
            setOpenDialogEmptySelection(true);
            return;
        }

        const source = axios.CancelToken.source();

        setIsLoading(true);
        setOpenDialogImport(true);

        const req = await request.post(
            `${process.env.REACT_APP_LEA_API}/import/create`,
            updatedTables,
            { headers: { "Content-Type": "application/json" } }, source
        );

        if (req.isSuccessful) {
            responseDataToJSX(req.response.data, setImportSummary);
            handleSuccess(req.response.data.message);
            setIsLoading(false);
        } else if (req.isSessionExpired) {
            handleError(req.error, () => {
                setOpenDialogImport(false);
                setTimeout(() => {
                    setIsLoading(false);
                }, 100);
                navigateToDisconnect();
            }, req.error?.response?.data?.message ?? request.EXPIRED_SESSION_MESSAGE, true);
        } else {
            // setIsThereAnomaly(true);
            if (req.error.response) {
                let errorMessage = req.error.response.data.message;

                handleError(req.error, () => {
                    if (req.error.response.data.message === "Problème lors de la création de l'import") {
                        setOpenDialogImport(false);
                        errorMessage = "Problème lors de la création de l'import.";
                    } else {
                        responseDataToJSX(
                            req.error.response.data,
                            setImportSummary,
                            req.error.response.status === 400 ? req.error.response.data.message : undefined
                        );
                    }
                    setTimeout(() => {
                        setIsLoading(false);
                    }, 100);
                }, errorMessage);
            }
        }
        return () => {
            source.cancel("Component unmounted.");
        };
    };

    return <>
        <div>
            <h3 className={"text-start"} style={{ marginTop: "2em", fontSize: "20px", fontWeight: "600", color: "#4F4D56" }}>Liste des tables trouvées dans la base {file?.name.slice(0, -4)}</h3>
            <p className={"text-start text-gray-600 text-base"}>
                Sélectionnez les fichiers que vous souhaitez importer dans LEA WEB 2
            </p>
            <p
                className={"text-gray-600 cursor-pointer"}
                onClick={() => setCompanyToEstablishment((companyToEstablishment: boolean) => !companyToEstablishment)}
            >
                <Checkbox checked={companyToEstablishment}/>
                La colonne SOCIETE contient l’ÉTABLISSEMENT
            </p>
        </div>
        <div className={"flex w-full max-h-[500px] pb-5"}>
            <TablePaginationPro
                isLoading={isTableLoading}
                data={data}
                origin={file?.name ?? ""}
                columns={columns}
                rows={rows}
                handleFilterSearch={handleFilterSearch}
                onSelect={(ids: GridRowId[]) => {
                    setSelectedRow(ids);
                }}
                emptyColumns={true}
            />
        </div>
        <div className={"flex justify-end"}>
            <CustomButton text={"Importer"} onClick={importTables}/>
            <DialogImportSummary
                isOpen={openDialogImport}
                isLoading={isLoading}
                onClose={handleCloseDialogImport}
                onRedirection={() => {
                    navigate("/dashboard/home");
                    setOpenDialogImport(false);
                }}
                content={
                    <DialogContent className={"font-worksans"}>
                        <h1 className={"flex h-full items-center mt-4 mb-1 text-gray-600 font-semibold"}>Récapitulatif</h1>
                        {importSummary}
                        {/* TODO: Anomaly report */}
                        {/*<AnomalyReport anomaly={isThereAnomaly}/>*/}
                    </DialogContent>
                }
            />
            <DialogEmptySelection
                open={openDialogEmptySelection}
                handleClose={handleCloseDialogEmptySelection}
                title={dialogEmptyTitle}
                content={dialogEmptyContent}
            />
        </div>
    </>;
}
