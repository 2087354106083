import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useHandleError } from "../../Tools/HandleError/HandleError";
import CustomButton from "../../Tools/Action/Button/ButtonCustom";
import axios from "axios";
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import * as request from "../../Tools/Utils/APIRequests/request";
import type {StateProps} from "../../../types";
import {FormatPerimeter} from "../../Dashboard/Evaluation/Library/ToolsLibrary/PerimeterSelection";
import {DEFAULT_PERIMETER} from "../../../constants";
import type {RangeDirection, RangeDivision, RangeLegalEntity, RangePole} from "../../Dashboard/FilterBar";
import {useDispatch} from "react-redux";

const isDEMOEnabled: boolean = process.env.REACT_APP_DEMO === '1';

interface LoginProps {
    navigateTo?: string,
    migrationLogin?: boolean,

    handleChangeConnected(newValue: boolean): any
}

export default function Login({navigateTo = "../../dashboard/home", migrationLogin = false, handleChangeConnected}: LoginProps) {
    const [isLoading, setIsLoading] = useState(false);
    const [passwordShown, setPasswordShown] = useState<boolean>(false);
    const handleError = useHandleError();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [password, setPassword] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [rememberMe, setRememberMe] = useState<boolean>(false);
    const [perimeter, setPerimeter]: StateProps<FormatPerimeter> = useState<FormatPerimeter>(JSON.parse(localStorage.getItem("PERIMETER") ?? "null")?.perimeter ?? DEFAULT_PERIMETER);
    // const [group, setGroup] = useState<string>('');

    useEffect(() => {
        handleChangeConnected(false);

        const savedEmail = localStorage.getItem('email');
        const savedPassword = localStorage.getItem('password');
        const savedRememberMe = localStorage.getItem("rememberMe");
        if (savedEmail && !migrationLogin) setEmail(savedEmail);
        if (savedPassword && !migrationLogin) setPassword(savedPassword);
        if (savedRememberMe && !migrationLogin) setRememberMe(JSON.parse(savedRememberMe));

        if (localStorage.getItem("JWT")) {
            return;
        }
        // eslint-disable-next-line
        const token = window.location.href.split("=");
        // @ts-ignore
        if (token && token.length === 2) {
            // @ts-ignore
            if (token[1][token[1].length - 1] === "#") {
                token[1] = token[1].slice(0, -1);
            }
            localStorage.setItem('JWT', token[1]);
            handleChangeConnected(true);
            navigate(navigateTo);
        }
    }, [handleChangeConnected, migrationLogin, navigate, navigateTo]);

    const togglePassword = () => {
        setPasswordShown(!passwordShown);
    };

    async function onSubmit(event: any) {
        event.preventDefault();
        try {
            setIsLoading(true);

            const response = await axios.post(
                `${process.env.REACT_APP_LEA_API}/connect/classic`,
                {
                    email: email,
                    password: password
                }
            );

            localStorage.setItem('JWT', response.data.token);
            localStorage.setItem('REFRESH', response.data.refresh_token);
            localStorage.setItem('EXPIRATION', response.data.refresh_token_expiration);

            await (async () => {
                const req = await request.get(`${process.env.REACT_APP_LEA_API}/user/me/perimetre`);
                if (req.isSuccessful) {
                    const pole = req.response.data.data.poles.map((item: RangePole) => ({
                        value: item.value,
                        id: item.value,
                        code: item.code_societe,
                        label: `${item.code_societe} - ${item.name}`,
                        nom: item.name
                    }));
                    const division = req.response.data.data.poles.flatMap((pole: RangePole) => (
                            pole.divisions.map((item: RangeDivision) => ({
                                value: item.value,
                                id: item.value,
                                code: item.code_societe,
                                label: `${item.code_societe} - ${item.name}`,
                                nom: item.name
                            }))
                        )
                    );
                    const direction_operationnelle = req.response.data.data.poles.flatMap((pole: RangePole) =>
                        pole.divisions.flatMap((division: RangeDivision) =>
                            division.directions_operationnelles.map((item: RangeDirection) => ({
                                value: item.value,
                                id: item.value,
                                code: item.code_societe,
                                label: `${item.code_societe} - ${item.name}`,
                                nom: item.name
                            }))
                        )
                    );
                    const entite_juridique = req.response.data.data.poles.flatMap((pole: RangePole) =>
                        pole.divisions.flatMap((division: RangeDivision) =>
                            division.directions_operationnelles.flatMap((direction: RangeDirection) =>
                                direction.entites_juridiques.map((item: RangeLegalEntity) => ({
                                    value: item.value,
                                    id: item.value,
                                    code: item.code_societe,
                                    label: `${item.code_societe} - ${item.name}`,
                                    nom: item.name
                                }))
                            )
                        )
                    );
                    setPerimeter({
                        ...perimeter,
                        pole: pole,
                        division: division,
                        direction_operationnelle: direction_operationnelle,
                        entite_juridique: entite_juridique
                    });

                    // Fix date format with milliseconds
                    let formattedDate = perimeter.date_import;
                    if (formattedDate && formattedDate.toString().length > 10) {
                        formattedDate = formattedDate / 1000;
                    }

                    localStorage.setItem("PERIMETER", JSON.stringify({
                        perimeter: {
                            ...perimeter,
                            pole: pole,
                            division: division,
                            direction_operationnelle: direction_operationnelle,
                            entite_juridique: entite_juridique
                        },
                        rawPerimeter: {
                            pole: pole.map((item: RangePole) => item.value),
                            division: division.map((item: RangeDivision) => item.value),
                            direction_operationnelle: direction_operationnelle.map((item: RangeDirection) => item.value),
                            entite_juridique: entite_juridique.map((item: RangeLegalEntity) => item.value),
                            id_import: perimeter.id_import?.value ?? null,
                            date_import: formattedDate
                        }
                    }));
                } else if (req.isSessionExpired) {
                    handleError(req.error, () => {
                    }, req.error?.response?.data?.message ?? request.EXPIRED_SESSION_MESSAGE, true);
                    dispatch({type: "RESET_PERIMETER"});
                } else if (req.error.response) {
                    handleError(req.error, () => {
                    }, req.error.response.data.message);
                }
            })();

            if (!migrationLogin) {
                if (rememberMe) {
                    localStorage.setItem('email', email);
                    localStorage.setItem('password', password);
                    localStorage.setItem("rememberMe", JSON.stringify(rememberMe));
                } else {
                    localStorage.removeItem('email');
                    localStorage.removeItem('password');
                    localStorage.removeItem("rememberMe");
                }
            }
            handleChangeConnected(true);
            navigate(navigateTo);
            setIsLoading(false);
        } catch (err: any) {
            if (err.response) {
                handleError(err, () => {
                    setIsLoading(false);
                    setPassword("");
                }, err.response.data.message ?? "Impossible de se connecter.");
            }
        }
    }

    return (
        <form onSubmit={onSubmit} className={"py-5"}>
            <div
                className="flex flex-col font-worksans bg-white shadow w-[560px] h-[660px] justify-between items-center space-y-6 rounded-lg">
                <div
                    className={"flex w-full h-[92px] bg-gradient-to-r rounded-t-lg from-BASE_PURPLE to-BASE_BLUE"}>
                    <div className={"flex flex-col ml-[20%] lg:ml-[83px] items-start justify-center"}>
                        <div className={"text-white font-normal text-xl"}>Connexion</div>
                        <div className={"text-white font-normal text-xs"}>Version 1.2.0</div>
                    </div>
                </div>
                <div className={"flex flex-col w-2/3 space-y-7"}>
                    <div className={"text-lg w-full text-start"}>
                        <p>Pour vous connecter, veuillez saisir vos identifiants d’accès.</p>
                    </div>
                    <div className={"flex flex-col space-y-4 w-full"}>
                        <div className={"flex flex-col space-y-1 items-start"}>
                            <div className={"text-lg ml-3"}>Groupe</div>
                            <div className="relative w-full">
                                <div
                                    className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                                    <img src={require("../../../Assets/Group 166.png")}
                                         className="w-5 h-5 text-gray-500" alt={"icon"}/>
                                </div>
                                <input
                                    disabled
                                    type="text"
                                    id="input-group-1" /* onChange={(e) => setGroup(e.target.value)} */
                                    className="bg-gray-200 border border-gray-300 text-gray-900 text-lg rounded-2xl focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5"
                                    placeholder={isDEMOEnabled ? "SECOIA" : "VINCI"}
                                    required/>
                            </div>
                        </div>
                        <div className={"flex flex-col space-y-1 items-start"}>
                            <div className={"text-lg ml-3"}>Adresse email</div>
                            <div className="relative w-full">
                                <div
                                    className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                                    <img src={require("../../../Assets/Identifiant.png")}
                                         className="w-5 h-7 mt-2 text-gray-500" alt={"icon"}/>
                                </div>
                                <input type="email" value={email} id="input-group-2" onChange={(e) => {
                                    setEmail(e.target.value);
                                }}
                                       className="bg-white border border-gray-300 text-gray-900 text-lg rounded-2xl focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5"
                                       placeholder={process.env.REACT_APP_DEMO === "1" ? "jean.dupont@entreprise.fr" : "jean.dupont@vinci.fr"} required/>
                            </div>
                        </div>
                        <div className={"flex flex-col space-y-1 items-start"}>
                            <div className={"text-lg ml-3"}>Mot de passe</div>
                            <div className="relative w-full">
                                <div
                                    className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                                    <img src={require("../../../Assets/bytesize_lock.png")}
                                         className="w-5 h-5 text-gray-500" alt={"icon"}/>
                                </div>
                                <input type={passwordShown ? "text" : "password"} value={password} id="input-group-3"
                                       onChange={(e) => {
                                           setPassword(e.target.value);
                                       }}
                                       className="bg-white border border-gray-300 text-gray-900 text-lg rounded-2xl focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5"
                                       placeholder="• • • • • • • • • •" required/>
                                <button type={"button"}
                                        className="flex absolute inset-y-0 right-0 items-center pr-3 z-30"
                                        onClick={togglePassword}>
                                    {!passwordShown ? (
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-500"
                                             fill="none"
                                             viewBox="0 0 24 24"
                                             stroke="currentColor" strokeWidth={2}>
                                            <path strokeLinecap="round" strokeLinejoin="round"
                                                  d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"/>
                                        </svg>) : (
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-500"
                                             fill="none"
                                             viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                            <path strokeLinecap="round" strokeLinejoin="round"
                                                  d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"/>
                                            <path strokeLinecap="round" strokeLinejoin="round"
                                                  d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"/>
                                        </svg>)}
                                </button>
                            </div>
                        </div>
                        {migrationLogin ? null : <FormGroup>
                            <FormControlLabel
                                className={"text-gray-600"}
                                control={
                                    <Checkbox
                                        checked={rememberMe}
                                        onChange={() => setRememberMe(!rememberMe)}
                                        color="primary"
                                    />
                                }
                                label="Se souvenir de moi"
                            />
                        </FormGroup>}
                    </div>
                </div>
                <div className={"flex flex-row space-x-5 pt-2 justify-center items-center"}>
                    <CustomButton
                        isLoading={isLoading}
                        text={"Connexion"}
                        type={"submit"}
                    />

                    {process.env.REACT_APP_DEMO !== '1' && (
                        <CustomButton
                            isLoading={isLoading}
                            text={"Office 365"}
                            link={`${process.env.REACT_APP_LEA_API}/connect/microsoft`}
                        />
                    )}
                </div>
                <div className={"flex flex-row justify-center items-center space-x-10 pb-5 text-sm"}>
                    <div className={"text-MAIN_BLUE hover:underline cursor-pointer"}
                         onClick={() => navigate("../missing-password")}>Mot de passe oublié ?
                    </div>
                    <div className={"text-MAIN_BLUE hover:underline cursor-pointer"}
                         onClick={() => navigate("../unknown-identifier")}>Identifiants inconnus ?
                    </div>
                </div>
            </div>
        </form>
    );
};