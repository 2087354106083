import axios, { AxiosRequestConfig, CancelTokenSource } from "axios";
import { setConfig, Request } from "./request";
import refreshToken from "./refreshToken";

/**
 * Send a PUT request to the API.
 * @param url The URL to send the request to.
 * @param data (optional) The data to send with the request.
 * @param config (optional) The config to send with the request.
 * @param source (optional) The CancelTokenSource to send with the request.
 * @param useRefreshToken (only for internal use) Whether to use the refresh token or not.
 * @param isSessionExpired (only for internal use) Whether the session is expired or not.
 * @returns Request statuses and responses => { isSuccessful: boolean, isSessionExpired: boolean, response: any | null, error: any | null } (if the error is null, the response should not, and vice versa).
 */
export default async function put(url: string,
                                   data?: any,
                                   config?: AxiosRequestConfig<any>,
                                   source?: CancelTokenSource,
                                   useRefreshToken: boolean = true,
                                   isSessionExpired: boolean = false): Promise<Request>
{
    const refresh = localStorage.getItem("REFRESH");

    if (refresh === null)
        useRefreshToken = false;
    config = setConfig(config ?? {}, source);
    try {
        return { isSuccessful: true, isSessionExpired: false, response: await axios.put(url, data, config), error: null };
    } catch (error: any) {
        if (axios.isCancel(error)) {
            return { isSuccessful: false, isSessionExpired: isSessionExpired, response: null, error: error };
        } else if (useRefreshToken) {
            if (!error.response || error.response.status !== 401)
                return { isSuccessful: false, isSessionExpired: false, response: null, error: error };

            const result = await refreshToken(refresh);

            if (result.result)
                return await put(url, data, config, source, false, false);
            return { isSuccessful: false, isSessionExpired: true, response: null, error: result.error };
        }
        return { isSuccessful: false, isSessionExpired: isSessionExpired, response: null, error: error };
    }
}
