import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import pageChangeProhibited from "../Tools/PageChangeProhibited/pageChangeProhibited";

const isDEMOEnabled: boolean = process.env.REACT_APP_DEMO === '1';

interface FooterProps {
    isConnected: boolean,
    handleChangeConnected(newValue: boolean): any
}

export default function Footer({isConnected, handleChangeConnected} : FooterProps) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const isPageChangeAllowed = useSelector((state: any) => state.isPageChangeAllowed);
    const currentYear = new Date().getFullYear();

    return (
        <footer className="bg-CUSTOM_BLUE h-10 flex items-center justify-center">
            <div className="flex-1"></div>

            <ul className="flex flex-row font-medium text-sm text-white space-x-5 font-worksans">
                <li className="hover:underline cursor-pointer" onClick={() => {
                    const nav = () => {
                        dispatch({type: "ALLOW_PAGE_CHANGE"});
                        navigate(isConnected ? "user-guide" : "../oauth/user-guide");
                    };

                    if (isPageChangeAllowed) nav();
                    else pageChangeProhibited(nav);
                }}>
                    {isDEMOEnabled ? "Guide" : "Guide et vidéos"}
                </li>

                <svg width="4" height="23" viewBox="0 0 2 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 0V22.5" stroke="white" strokeOpacity="0.34"/>
                </svg>

                <li className="hover:underline cursor-pointer" onClick={() => {
                    const nav = () => {
                        dispatch({ type: "ALLOW_PAGE_CHANGE" });
                        navigate(isConnected ? "support" : "../oauth/support");
                    };

                    if (isPageChangeAllowed) nav();
                    else pageChangeProhibited(nav);
                }}>
                    Support
                </li>

                <svg width="4" height="23" viewBox="0 0 2 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 0V22.5" stroke="white" strokeOpacity="0.34"/>
                </svg>

                <li className="hover:underline cursor-pointer" onClick={() => {
                    const nav = () => {
                        dispatch({ type: "ALLOW_PAGE_CHANGE" });
                        navigate(isConnected ? "legal-notice" : "../oauth/legal-notice");
                    };

                    if (isPageChangeAllowed) nav();
                    else pageChangeProhibited(nav);
                }}>
                    Mentions légales
                </li>

                <svg width="4" height="23" viewBox="0 0 2 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 0V22.5" stroke="white" strokeOpacity="0.34"/>
                </svg>

                <li className="hover:underline cursor-pointer" onClick={async () => {
                    const nav = () => {
                        dispatch({ type: "ALLOW_PAGE_CHANGE" });
                        handleChangeConnected(false);
                        return navigate(isConnected ? "disconnect" : "../oauth/login");
                    };

                    if (isPageChangeAllowed) nav();
                    else  pageChangeProhibited(nav);
                }}>
                    {isConnected ? "Déconnexion" : "Connexion"}
                </li>
            </ul>

            <div className="flex-1 ml-5">
                <div className={"font-medium font-worksans text-sm text-white"}>
                    SECOIA © {currentYear} - tous droits réservés
                </div>
            </div>
        </footer>
    );
}