import {Disclosure} from "@headlessui/react";
import {useNavigate} from "react-router-dom";

interface HeaderProps {
    navigateTo?: string
}

export default function Header({navigateTo = "login"}: HeaderProps) {
    const navigate = useNavigate();

    return <header>
        <Disclosure as="nav" className="bg-gradient-to-r from-BASE_PURPLE to-BASE_BLUE">
            <div className="max-w-7xl px-2 sm:px-6 lg:px-4">
                <div className="flex flex-row items-center h-14">
                    <img src={require('../../Assets/Logo.png')} alt="logo" className={"h-[70%] cursor-pointer"} onClick={() => navigate(navigateTo)}/>
                    <div className={"text-white text-base font-worksans font-normal ml-6"}>Solution de calcul des
                        engagements
                        sociaux
                        différés des entreprises
                    </div>
                </div>
            </div>
        </Disclosure>
    </header>;
}