import React, { useState } from "react";
import type { JSX } from "react";

const isDEMOEnabled: boolean = process.env.REACT_APP_DEMO === '1';


type Guide = {
    id: number;
    fileName?: string;
    videoSrc?: string;
    title: string;
};

const GENERAL: Guide[] = [
    {
        id: 1,
        fileName: isDEMOEnabled ? "Guide-utilisation-entier-espace-demo.pdf" : "Guide-utilisation-LEA2-2024.pdf",
        title: "Voir le guide utilisateur",
    },
    ...(!isDEMOEnabled ? [
        {
            id: 2,
            videoSrc: process.env.PUBLIC_URL + "/User-guide/importer_fichier_salarie.mp4",
            title: "Importer un fichier salarié",
        },
        {
            id: 3,
            videoSrc: process.env.PUBLIC_URL + "/User-guide/creer_modifier_supprimer_etude.mp4",
            title: "Créer, modifier, supprimer une étude",
        },
        {
            id: 4,
            videoSrc: process.env.PUBLIC_URL + "/User-guide/creer_modifier_supprimer_evaluation_simple.mp4",
            title: "Créer, modifier, supprimer une évaluation simple",
        }
    ] : []),
];

const EVALUATION: Guide[] = [
    {
        id: 1,
        fileName: "import_salarie.pdf",
        title: "Importer un fichier salarié",
    },
    {
        id: 2,
        fileName: "parametrer_etude.pdf",
        title: "Paramétrer une étude",
    },
    {
        id: 3,
        fileName: "parametrer_evaluation.pdf",
        title: "Lancer une évaluation",
    },
    {
        id: 4,
        fileName: "consulter_resultat.pdf",
        title: "Consulter les résultats",
    },
];

const SUPERVISION: Guide[] = [
    {
        id: 1,
        fileName: "supervision.pdf",
        title: "Supervision",
    }
];

const CONSOLIDATION: Guide[] = [
    {
        id: 1,
        fileName: "consolidation.pdf",
        title: "Consolidation",
    },
];

export default function UserGuide(): JSX.Element {
    const [videoSrc, setVideoSrc] = useState<string | null>(null);
    const [videoTitle, setVideoTitle] = useState<string | null>(null);

    const onDownload = (file: Guide) => {
        if (file.fileName) {
            const link = document.createElement("a");
            link.download = file.fileName;
            link.href = process.env.PUBLIC_URL + "/User-guide/" + file.fileName;
            link.click();
        }
    };

    const onOpenVideo = (videoSrc: string | undefined, title: string) => {
        if (videoSrc) {
            setVideoSrc(videoSrc);
            setVideoTitle(title);
        }
    };

    const onCloseVideo = () => {
        setVideoSrc(null);
        setVideoTitle(null);
    };

    return (
        <div className={"flex flex-col h-full w-full p-5 font-worksans"}>
            <div className={"flex flex-col w-full h-full pt-10 px-5 space-y-5"}>
                <div className={"flex flex-col space-y-1 items-start"}>
                    <div className={"text-2xl text-gray-800 font-semibold"}>Guide d'utilisation</div>
                    <div className={"text-lg text-gray-800 font-base"}>
                        Consultez la documentation pour comprendre le fonctionnement de LEA
                    </div>
                </div>

                <div className={"flex flex-col w-full space-y-5 items-start"}>
                    <div className={"text-xl text-gray-800 font-semibold"}>
                        Général
                    </div>
                    <div
                        className={"grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 w-full px-5 gap-5"}>
                        {GENERAL.map((file, index) => (
                            <button
                                key={index}
                                onClick={() => file.fileName ? onDownload(file) : onOpenVideo(file.videoSrc, file.title)}
                                className="flex col-span-1 w-full bg-white px-2 py-5 cursor-pointer hover:bg-MAIN_BLUE hover:text-white text-MAIN_BLUE shadow rounded-lg"
                            >
                                <div className="flex flex-row w-full items-center font-semibold">
                                    <div className="flex-shrink-0">
                                        {file.fileName ? (
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10" fill="none"
                                                 viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                                <path strokeLinecap="round" strokeLinejoin="round"
                                                      d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"/>
                                            </svg>
                                        ) : (
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10"
                                                 fill="currentColor" viewBox="0 -960 960 960">
                                                <path
                                                    d="m380-300 280-180-280-180v360ZM200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h560q33 0 56.5 23.5T840-760v560q0 33-23.5 56.5T760-120H200Zm0-80h560v-560H200v560Zm0-560v560-560Z"/>
                                            </svg>
                                        )}
                                    </div>
                                    <div className="flex-grow text-start px-3">
                                        {file.title}
                                    </div>
                                </div>
                            </button>
                        ))}
                    </div>
                </div>

                {!isDEMOEnabled && (
                    <>
                        <div className={"flex flex-col w-full space-y-5 items-start"}>
                            <div className={"text-xl text-gray-800 font-semibold"}>
                                L'évaluation
                            </div>
                            <div
                                className={"grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 w-full px-5 gap-5"}>
                                {EVALUATION.map((file, index) => (
                                    <button
                                        key={index}
                                        onClick={() => file.fileName ? onDownload(file) : onOpenVideo(file.videoSrc, file.title)}
                                        className="flex col-span-1 w-full bg-white px-2 py-5 cursor-pointer hover:bg-MAIN_BLUE hover:text-white text-MAIN_BLUE shadow rounded-lg"
                                    >
                                        <div className="flex flex-row w-full items-center font-semibold">
                                            <div className="flex-shrink-0">
                                                {file.fileName ? (
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10" fill="none"
                                                         viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                                        <path strokeLinecap="round" strokeLinejoin="round"
                                                              d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"/>
                                                    </svg>
                                                ) : (
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10"
                                                         fill="currentColor" viewBox="0 -960 960 960">
                                                        <path
                                                            d="m380-300 280-180-280-180v360ZM200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h560q33 0 56.5 23.5T840-760v560q0 33-23.5 56.5T760-120H200Zm0-80h560v-560H200v560Zm0-560v560-560Z"/>
                                                    </svg>
                                                )}
                                            </div>
                                            <div className="flex-grow text-start px-3">
                                                {file.title}
                                            </div>
                                        </div>
                                    </button>
                                ))}
                            </div>
                        </div>

                        <div className={"flex flex-col w-full space-y-5 items-start"}>
                            <div className={"text-xl text-gray-800 font-semibold"}>
                                La supervision
                            </div>
                            <div
                                className={"grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 w-full px-5 gap-5"}>
                                {SUPERVISION.map((file, index) => (
                                    <button
                                        key={index}
                                        onClick={() => file.fileName ? onDownload(file) : onOpenVideo(file.videoSrc, file.title)}
                                        className="flex col-span-1 w-full bg-white px-2 py-5 cursor-pointer hover:bg-MAIN_BLUE hover:text-white text-MAIN_BLUE shadow rounded-lg"
                                    >
                                        <div className="flex flex-row w-full items-center font-semibold">
                                            <div className="flex-shrink-0">
                                                {file.fileName ? (
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10" fill="none"
                                                         viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                                        <path strokeLinecap="round" strokeLinejoin="round"
                                                              d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"/>
                                                    </svg>
                                                ) : (
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10"
                                                         fill="currentColor" viewBox="0 -960 960 960">
                                                        <path
                                                            d="m380-300 280-180-280-180v360ZM200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h560q33 0 56.5 23.5T840-760v560q0 33-23.5 56.5T760-120H200Zm0-80h560v-560H200v560Zm0-560v560-560Z"/>
                                                    </svg>
                                                )}
                                            </div>
                                            <div className="flex-grow text-start px-3">
                                                {file.title}
                                            </div>
                                        </div>
                                    </button>
                                ))}
                            </div>
                        </div>

                        <div className={"flex flex-col w-full space-y-5 items-start"}>
                        <div className={"text-xl text-gray-800 font-semibold"}>
                            La consolidation
                        </div>
                        <div
                            className={"grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 w-full px-5 gap-5"}>
                            {CONSOLIDATION.map((file, index) => (
                                <button
                                    key={index}
                                    onClick={() => file.fileName ? onDownload(file) : onOpenVideo(file.videoSrc, file.title)}
                                    className="flex col-span-1 w-full bg-white px-2 py-5 cursor-pointer hover:bg-MAIN_BLUE hover:text-white text-MAIN_BLUE shadow rounded-lg"
                                >
                                    <div className="flex flex-row w-full items-center font-semibold">
                                        <div className="flex-shrink-0">
                                            {file.fileName ? (
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10" fill="none"
                                                     viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                                    <path strokeLinecap="round" strokeLinejoin="round"
                                                          d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"/>
                                                </svg>
                                            ) : (
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10"
                                                     fill="currentColor" viewBox="0 -960 960 960">
                                                    <path
                                                        d="m380-300 280-180-280-180v360ZM200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h560q33 0 56.5 23.5T840-760v560q0 33-23.5 56.5T760-120H200Zm0-80h560v-560H200v560Zm0-560v560-560Z"/>
                                                </svg>
                                            )}
                                        </div>
                                        <div className="flex-grow text-start px-3">
                                            {file.title}
                                        </div>
                                    </div>
                                </button>
                            ))}
                        </div>
                    </div>
                    </>
                )}
            </div>

            {videoSrc && !isDEMOEnabled && (
                <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-[9999]">
                    <div className="bg-white rounded-lg overflow-hidden shadow-lg w-3/4 relative">
                        <div className="flex justify-between items-center p-4"
                             style={{background: 'linear-gradient(90deg, #5D39AF 0%, #2E8FF6 100%)'}}>
                            <h2 className="text-lg text-white">{videoTitle}</h2>
                            <button onClick={onCloseVideo} className="text-white text-2xl">
                                &#x2715;
                            </button>
                        </div>
                        <div className="p-4">
                            <video controls src={videoSrc} className="w-full h-auto"></video>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
