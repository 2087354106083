import React, { useState } from "react";
import { Popover, Typography } from "@mui/material";
import AccessControl, { READ, SCOPE_WINDOW } from "../../AccessControl/AccessControl";
import AnchorButton from "./AnchorButton";

interface AppBarButtonProps {
    idModules: string,
    item: any,
}

export default function AppBarButton({idModules, item}: AppBarButtonProps) {

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const handleOpenDropDown = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseDropDown = () => {
        setAnchorEl(null);
    };

    return <>
        {!item.menu.length ?
            <AnchorButton
                className={`flex items-center ${idModules === item.path ? "bg-white text-white h-full bg-opacity-10" : "text-gray-200 h-full"} hover:text-white px-3 rounded-t-md text-md font-medium z-30`}
                url={`/dashboard/${item.path}`}
                value={item.name}
            /> :
            <button type="button" onClick={handleOpenDropDown}
                    data-dropdown-toggle={"dropdown"}
                    className={`flex items-center ${idModules === item.path ? "bg-white text-white h-full bg-opacity-10" : "text-gray-200 h-full"} hover:text-white px-3 rounded-t-md text-lg font-medium`}>
                <Typography className={'z-30'}>{item.name}</Typography>
                {!!item.menu.length &&
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                         strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                        <path strokeLinecap="round" strokeLinejoin="round"
                              d="M19.5 8.25l-7.5 7.5-7.5-7.5"/>
                    </svg>}
            </button>
        }
        <Popover
            id={"dropdown"}
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handleCloseDropDown}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            sx={{
                '& .MuiPaper-root': {
                    borderRadius: 'initial',
                    borderBottomLeftRadius: '10px',
                    borderBottomRightRadius: '10px',
                    backgroundColor: '#4A5BCB',
                }
            }}
        >
            <div className="flex w-full h-full z-10">
                <ul className="flex flex-col w-full items-center bg-[#4A5BCB] font-worksans text-base z-50 py-2 text-left">
                    {item.menu.map((elem: any, count: number) =>
                        <AccessControl key={`${count} - ${elem.path} - ${elem.name}`} section={elem.rules} scope={SCOPE_WINDOW} right={READ}>
                            <li key={`${elem.path} - ${count}`} className={"w-full px-5 text-white hover:bg-white hover:text-[#4A5BCB]"}>
                                <AnchorButton
                                    className={"flex text-md py-2 font-normal w-full whitespace-nowrap"}
                                    url={`/dashboard/${item.path}/${elem.path}`}
                                    value={elem.name}
                                    onClick={handleCloseDropDown}
                                />
                            </li>
                        </AccessControl>)}
                </ul>
            </div>
        </Popover>
    </>;
}