import { useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField } from "@mui/material";
import CustomButton from "../../Action/Button/ButtonCustom";
import exportPDF from "../../Action/Export/ExportPDF";
import exportXLSX from "../../Action/Export/ExportXLSX";
import { useHandleError } from "../../HandleError/HandleError";
import { useHandleSuccess } from "../../HandleSuccess/HandleSuccess";
import * as request from "../../Utils/APIRequests/request";

interface DialogFilenameProps {
    open: boolean,

    handleClose(): void,

    origin: string,
    type: "PDF" | "EXCEL" | "PDF_ALL" | "EXCEL_ALL",
    data: any,
    allData: any[],
    exportUrl: string | null,

    formatDataWithoutSelect(newData: any, isExcel: boolean): any[],

    filter: any | null,
}

export default function DialogFilename({
                                           open,
                                           handleClose,
                                           type,
                                           origin,
                                           data,
                                           allData,
                                           exportUrl,
                                           formatDataWithoutSelect,
                                           filter
                                       }: DialogFilenameProps) {
    const [filename, setFilename] = useState<string>(origin);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleSuccess = useHandleSuccess();
    const handleError = useHandleError();
    const navigate = useNavigate();

    const navigateToDisconnect = useCallback(() => navigate("/dashboard/disconnect"), [navigate]);

    async function onSubmit(event: any) {
        event.preventDefault();
        setIsLoading(true);
        let req;
        if (exportUrl && (type === "PDF_ALL" || type === "EXCEL_ALL")) {
            if (filter)
                req = await request.post(exportUrl, filter);
            else
                req = await request.get(exportUrl);
            if (req.isSuccessful) {
                if (type === "PDF_ALL")
                    await exportPDF(formatDataWithoutSelect(req.response.data.data, false), filename);
                else if (type === "EXCEL_ALL")
                    await exportXLSX(formatDataWithoutSelect(req.response.data.data, true), filename);
                // handleSuccess(req.response.data.message);
            } else if (req.isSessionExpired) {
                handleError(req.error, () => {
                    setIsLoading(false);
                    navigateToDisconnect();
                }, req.error?.response?.data?.message ?? request.EXPIRED_SESSION_MESSAGE);
                return;
            } else {
                handleError(req.error, () => setIsLoading(false), req.error.response.data.message);
                return;
            }
        } else if (type === "PDF_ALL" || type === "EXCEL_ALL") {
            if (type === "PDF_ALL") {
                await exportPDF(formatDataWithoutSelect(allData, false), filename);
            } else if (type === "EXCEL_ALL") {
                await exportXLSX(formatDataWithoutSelect(allData, true), filename);
            }
        } else {
            if (type === "PDF") {
                await exportPDF(data(false), filename);
            } else if (type === "EXCEL") {
                await exportXLSX(data(true), filename);
            }
        }
        handleSuccess("Export réussi.");
        handleClose();
        setFilename(origin);
        setIsLoading(false);
    }

    // @ts-ignore
    return <Dialog component={'form'} open={open} onClose={handleClose} onSubmit={onSubmit} fullWidth maxWidth={'sm'}>
        <DialogTitle className="bg-gradient-to-r from-BASE_PURPLE to-BASE_BLUE rounded-t text-white">
            Export {type === "PDF" ? "pdf" : "excel"}
        </DialogTitle>
        <DialogContent>
            <TextField
                sx={{mt: 2}}
                label={'Nom du fichier'}
                required
                variant={'outlined'}
                fullWidth
                value={filename}
                onChange={(event: any) => setFilename(event.target.value)}
                inputProps={{autoComplete: 'off'}}/>
        </DialogContent>
        <DialogActions>
            <CustomButton text={'Retour'} onClick={handleClose}/>
            <CustomButton isLoading={isLoading} text={'Exporter'} type={'submit'}/>
        </DialogActions>
    </Dialog>;
}