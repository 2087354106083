import CustomButton from "../../Tools/Action/Button/ButtonCustom";
import {useState} from "react";

export default function FirstLogin() {
    const [newPasswordShown, setNewPasswordShown] = useState(false);
    const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);

    const toggleNewPassword = () => {
        setNewPasswordShown(!newPasswordShown);
    };

    const toggleConfirmPassword = () => {
        setConfirmPasswordShown(!confirmPasswordShown);
    };

    return <form className={"py-10"}>
        <div
            className="flex flex-col bg-white w-[563px] h-[563px] font-worksans shadow justify-between items-center space-y-6 rounded-lg">
            <div
                className={"flex w-full h-[92px] bg-gradient-to-r rounded-t-lg from-BASE_PURPLE to-BASE_BLUE"}>
                <div className={"flex flex-col ml-[20%] lg:ml-[83px] items-start justify-center"}>
                    <div className={"text-white font-normal text-sm lg:text-xl"}>Première connexion</div>
                </div>
            </div>
            <div className={"flex flex-col w-2/3 space-y-7"}>
                <div className={"text-sm w-full text-start"}>
                    <p>Merci de modifier votre mot de passe pour votre première connexion.</p>
                </div>
                <div className={"flex flex-col space-y-4 w-full"}>
                    <div className={"flex flex-col space-y-1 items-start"}>
                        <div className={"text-sm ml-3"}>Nouveau mot de passe</div>
                        <div className="relative w-full">
                            <div
                                className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                                <img src={require("../../../Assets/bytesize_lock.png")}
                                     className="w-5 h-5 text-gray-500" alt={"icon"}/>
                            </div>
                            <input type={newPasswordShown ? "text" : "password"} id="input-group-3" onChange={() => {}}
                                   className="bg-white border border-gray-300 text-gray-900 text-sm rounded-2xl focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5"
                                   placeholder="• • • • • • • • • •" required/>
                            <button className="flex absolute inset-y-0 right-0 items-center pr-3"
                                    onClick={toggleNewPassword}>
                                <img className="w-4 h-4 text-gray-500" src={require("../../../Assets/coolicon.png")}
                                     alt={"logo"}/>
                            </button>
                        </div>
                    </div>
                    <div className={"flex flex-col space-y-1 items-start"}>
                        <div className={"text-sm ml-3"}>Confirmer le mot de passe</div>
                        <div className="relative w-full">
                            <div
                                className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                                <img src={require("../../../Assets/bytesize_lock.png")}
                                     className="w-5 h-5 text-gray-500" alt={"icon"}/>
                            </div>
                            <input type={confirmPasswordShown ? "text" : "password"} id="input-group-3"
                                   onChange={() => {}}
                                   className="bg-white border border-gray-300 text-gray-900 text-sm rounded-2xl focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5"
                                   placeholder="• • • • • • • • • •" required/>
                            <button className="flex absolute inset-y-0 right-0 items-center pr-3"
                                    onClick={toggleConfirmPassword}>
                                <img className="w-4 h-4 text-gray-500" src={require("../../../Assets/coolicon.png")}
                                     alt={"logo"}/>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className={"flex pb-10 justify-center items-center"}>
                <CustomButton text={"Connexion"} type={"submit"}/>
            </div>
        </div>
    </form>;
}
